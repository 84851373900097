<template>
  <div>
    <v-btn-toggle v-model="type" color="info" dense mandatory>
      <v-btn value="Hälytys">Hälytys</v-btn>
      <v-btn value="Harjoitus">Harjoitus</v-btn>
      <v-btn value="Muu">Muu</v-btn>
    </v-btn-toggle>
    <slot name="selection"></slot>
    <v-card-actions>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        hide-details
        label="Haku"
        single-line
      ></v-text-field>
    </v-card-actions>
    <v-data-table
      :disable-pagination="true"
      :headers="headers"
      :items="filteredDigivihot"
      :items-per-page="-1"
      :loading="loading"
      :search="search"
    >
      <template v-slot:item.date="{ item }">
        <span v-if="item.type === 'Harjoitus'">
          {{ $formatDate(item.date + " " + item.time) }}
        </span>
        <span v-else>
          {{ $formatDateAndHourMinute(item.date + " " + item.time) }}
        </span>
      </template>
      <template v-slot:item.duration="{ item }">
        {{ item.duration }}
        <span v-if="item._biggestParticipantDiff" class="info--text"
          >({{ durationFromDiff(item._biggestParticipantDiff) }})</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-spacer />
        <v-btn icon @click="moveToDigivihko(item)">
          <v-icon>mdi mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { orderBy } from "lodash";

export default {
  name: "DigivihotTable",
  props: {
    digivihot: Array,
    loading: Boolean,
    year: Number,
  },
  data() {
    return {
      search: "",
      halytysHeaders: [
        { text: "Numero", value: "number" },
        { text: "Koodi", value: "code" },
        { text: "Osoite", value: "address" },
        { text: "Päivämäärä", value: "date" },
        { text: "Kesto", value: "duration" },
        { text: "Vahvuus", value: "strength" },
        { text: "Yksiköt", value: "engines" },
        { text: "", value: "actions" },
      ],
      harjoitusHeaders: [
        { text: "Numero", value: "number" },
        { text: "Aihe", value: "subject" },
        { text: "Päivämäärä", value: "date" },
        //{ text: "Kesto", value: "duration" },
        { text: "Osallistujat", value: "strength" },
        { text: "", value: "actions" },
      ],
      muuHeaders: [
        { text: "Numero", value: "number" },
        { text: "Aihe", value: "subject" },
        { text: "Päivämäärä", value: "date" },
        { text: "Kesto", value: "duration" },
        { text: "Osallistujat", value: "strength" },
        { text: "", value: "actions" },
      ],

      type: "Hälytys",
    };
  },
  emits: ["update:type"],
  watch: {
    type() {
      this.$emit("update:type", this.type);
    },
  },
  computed: {
    headers() {
      switch (this.type) {
        case "Hälytys":
          return this.halytysHeaders;
        case "Harjoitus":
          return this.harjoitusHeaders;
        case "Muu":
          return this.muuHeaders;
        default:
          return this.muuHeaders;
      }
    },
    yksikot() {
      return this.$store.state.yksikot;
    },
    filteredDigivihot() {
      const years = {};
      let digivihot = [...this.digivihot]
        .filter((d) => d.type === this.type)
        .reverse()
        .map((v, i) => {
          const year = dayjs(v.date).year();
          if (!years[year]) years[year] = 0;
          years[year] += 1;
          v.number = years[year];
          v.duration = this.duration(v);
          v.diff = this.diff(v);
          v._biggerDiff = [];
          v.participants?.forEach((p) => {
            const pdiff = this.diff(p);
            if (pdiff > v.diff) {
              v._biggerDiff.push(pdiff);
            }
          });
          v.strength = v.participants?.length;
          v.engines = orderBy(
            Array.from(new Set(v.participants?.map((p) => p.engine))).map((e) =>
              this.yksikot.find((y) => y.id === e)
            ),
            ["id"]
          )
            .map((e) => e?.name)
            .filter(Boolean)
            .join(", ");
          v._biggerDiff = v._biggerDiff.filter(Boolean);
          v._biggestParticipantDiff =
            v.diff < Math.max(...v._biggerDiff)
              ? Math.max(...v._biggerDiff)
              : false;
          return v;
        });
      digivihot.reverse();
      return digivihot;
    },
  },
  methods: {
    diff(digivihko) {
      if (
        digivihko.date &&
        digivihko.time &&
        digivihko.dateReturn &&
        digivihko.timeReturn
      ) {
        const startTime = dayjs(
          digivihko.date + " " + digivihko.time,
          "YYYY-MM-DD HH:mm"
        );
        const returnTime = dayjs(
          digivihko.dateReturn + " " + digivihko.timeReturn,
          "YYYY-MM-DD HH:mm"
        );
        return returnTime.diff(startTime);
      }
      return null;
    },
    duration(digivihko) {
      const diff = this.diff(digivihko);
      return this.durationFromDiff(diff);
    },
    durationFromDiff(diff) {
      if (diff) {
        const dur = dayjs.duration(diff);
        let f = dur.format("H[h] m[m]");
        if (dur.as("days") > 1) {
          f = dur.format("D[d] H[h] m[m]");
        }
        return f;
      }
      return null;
    },
    moveToDigivihko(digivihko) {
      this.$router.push({
        name: "DigivihkoById",
        params: { id: this.$id(digivihko) },
      });
    },
  },
};
</script>

<style></style>
