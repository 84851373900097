<template>
  <basic-page title="Tiedostopankki" fluid>
    <v-row class="fill-height">
      <v-col cols="12" lg="10" xl="6" offset-xl="3" offset-lg="1">
        <v-card class="mt-5">
          <v-card-title
            >Tiedostopankki
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <span class="text-subtitle-1">Viimeisimmät tiedostot</span>
                <template v-if="loading">
                  <v-skeleton-loader
                    v-if="loading"
                    type="list-item@5"
                  ></v-skeleton-loader>
                </template>
                <v-list>
                  <template v-for="file in latestFiles">
                    <v-list-item :key="file.id" two-line class="mb-n8">
                      <v-list-item-content>
                        <v-list-item-title
                          :key="file.id"
                          class="text-wrap info--text d-flex align-center mb-3 mt-6 cursor-pointer"
                          @click="openFile(file)"
                        >
                          <v-icon class="mr-2"
                            >mdi {{ getFileIcon(file.fileExtension) }}
                          </v-icon>
                          <div class="text-wrap" style="word-break: break-word">
                            {{ file.filename }}
                          </div>
                        </v-list-item-title>
                        <v-spacer />
                        <div class="ml-8 mt-n3 caption">
                          {{ $formatDateAndHourMinute(file.createdAt) }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-divider class="mt-5 mb-5" />
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="search"
                  label="Hae tiedostoista"
                  flat
                  hide-details
                  clearable
                >
                  <template v-slot:append-outer>
                    <v-btn icon @click="additionalFilters = !additionalFilters"
                      ><v-icon>mdi mdi-filter</v-icon></v-btn
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="additionalFilters">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <span class="text-subtitle-2"> Lisäysvuosi</span>
                    <v-chip-group column v-model="filters.year" multiple>
                      <v-chip
                        v-for="year in years"
                        :key="year"
                        :value="year"
                        class="mr-2"
                        outlined
                        color="primary"
                        >{{ year }}</v-chip
                      >
                    </v-chip-group>
                  </v-col>
                </v-row>
                <v-divider />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>
            <v-btn
              :disabled="loading"
              @click="
                () => {
                  $refs.treeview.updateAll(updateAll);
                  updateAll = !updateAll;
                }
              "
              class="mr-2"
              elevation="0"
            >
              <v-icon
                >mdi
                {{
                  updateAll
                    ? "mdi-unfold-more-horizontal"
                    : "mdi-unfold-less-horizontal"
                }}</v-icon
              >
              {{ updateAll ? "Avaa kaikki" : "Sulje kaikki" }}
            </v-btn>
            <v-spacer />
            <div>
              <v-btn
                color="primary"
                rounded
                v-if="editMode"
                class="mr-2"
                @click="
                  () => {
                    addModal.category.parentCategoryId = null;
                    addModal.file.categoryId = null;
                    showAddModal = true;
                  }
                "
              >
                <v-icon>mdi mdi-plus</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                rounded
                @click="editMode = !editMode"
                v-if="hasEditPermissions"
              >
                <v-icon v-if="!editMode">mdi mdi-pencil</v-icon>
                <v-icon v-if="editMode">mdi mdi-pencil-off</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text class="mb-10">
            <template v-if="loading">
              <v-skeleton-loader
                v-if="loading"
                type="list-item@3"
              ></v-skeleton-loader>
            </template>
            <v-treeview
              v-if="!loading"
              :items="items"
              activatable
              open-on-click
              ref="treeview"
              :search="search"
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="item.type === 'category'"
                  >mdi {{ open ? "mdi-folder-open" : "mdi-folder" }}
                </v-icon>
                <v-icon v-if="item.type === 'file'"
                  >mdi {{ getFileIcon(item.fileExtension) }}
                </v-icon>
              </template>
              <template v-slot:label="{ item, open }">
                <tiedostopankki-item
                  :edit-mode="editMode"
                  :item="item"
                  :open="open"
                  :open-file="() => openFile(item)"
                />
              </template>
              <template v-slot:append="{ item }" v-if="editMode">
                <v-btn
                  icon
                  @click.stop="confirmDeleteCategory(item)"
                  v-if="item.type === 'category' && hasDeletePermission(item)"
                  class="error--text"
                  :loading="item._loadingCategoryDelete"
                >
                  <v-icon>mdi mdi-close</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click.stop="confirmDeleteFile(item)"
                  v-if="item.type === 'file' && hasDeletePermission(item)"
                  class="mr-9 error--text"
                  :loading="item._loadingFileDelete"
                >
                  <v-icon>mdi mdi-close</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class=""
                  @click.stop="
                    () => {
                      addModal.category.parentCategoryId = item.id;
                      addModal.file.categoryId = item.id;
                      showAddModal = true;
                    }
                  "
                  v-if="item.type === 'category'"
                >
                  <v-icon>mdi mdi-plus</v-icon>
                </v-btn>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-dialog v-model="showAddModal" max-width="600">
        <v-form ref="form">
          <v-card>
            <v-card-title>
              <h3>
                Lisää
                <span v-if="addModal.file.categoryId">tiedosto tai</span> kansio
              </h3>
              <v-spacer />
              <v-btn icon @click="showAddModal = false">
                <v-icon>mdi mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <span class="text-subtitle-1"
                ><strong>Yläkansio:</strong>
                {{ addModalParentCategories || "Ei yläkansiota" }}</span
              >
            </v-card-text>

            <v-card-title>
              <h5>Uusi kansio</h5>
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="Nimi"
                v-model="addModal.category.name"
                outlined
              ></v-text-field>
              <!--<v-text-field
                label="Kuvaus"
                v-model="addModal.category.description"
                outlined
              ></v-text-field>-->
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="addModal.category.departments"
                    :items="departments"
                    multiple
                    item-text="label"
                    item-value="value"
                    label="Osastot"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="saveCategory"
                :loading="loadingCategorySave"
              >
                Tallenna kategoria
              </v-btn>
            </v-card-actions>
            <template v-if="addModal.file.categoryId">
              <v-divider></v-divider>
              <v-card-title>
                <h5>Uusi tiedosto</h5>
              </v-card-title>
              <v-card-text>
                <file-upload
                  ref="upload"
                  :multiple="true"
                  @update:files="changeAddModalFileNameIfEmpty"
                ></file-upload>
                <!--<v-text-field
                  label="Nimi"
                  v-model="addModal.file.name"
                  outlined
                ></v-text-field>-->
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="addModal.file.departments"
                      :items="departments"
                      multiple
                      item-text="label"
                      item-value="value"
                      label="Osastot"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="saveFiles"
                  :loading="loadingFileSave"
                >
                  Tallenna tiedosto
                </v-btn>
              </v-card-actions>
            </template>
          </v-card></v-form
        >
      </v-dialog>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import api from "@/api";
import FileUpload from "@/components/FileUpload.vue";
import { getBlob, ref } from "firebase/storage";
import downloadjs from "downloadjs";
import { tiedostopankkiStorage } from "@/plugins/firebase.app";
import { orderBy } from "lodash";
import TiedostopankkiItem from "@/components/TiedostopankkiItem.vue";

export default {
  name: "Tiedostopankki",
  components: { TiedostopankkiItem, FileUpload, BasicPage },
  data: () => {
    return {
      loading: false,
      categories: [],
      files: [],
      showAddModal: false,
      editMode: false,
      updateAll: true,
      search: null,
      additionalFilters: false,
      addModal: {
        category: {
          name: "",
          description: "",
          parentCategoryId: null,
          departments: [],
        },
        file: {
          name: "",
          categoryId: null,
          description: "",
          departments: [],
        },
      },
      loadingFileSave: false,
      loadingCategorySave: false,
      filters: {
        year: null,
      },
    };
  },
  computed: {
    latestFiles() {
      return orderBy(this.files, "createdAt", "desc").slice(0, 5);
    },
    today() {
      return dayjs().format("LLLL");
    },
    years() {
      return new Set([
        ...this.files.map((f) => dayjs(f.createdAt).format("YYYY")),
        "2023",
      ]);
    },
    items() {
      return orderBy(
        this.categories
          .filter((c) => !c.parentCategoryId)
          .flatMap((category) => {
            return this.getCategory(category);
          }),
        "name"
      );
    },
    addModalParentCategories() {
      if (this.addModal.category && this.addModal.category.parentCategoryId) {
        return this.getParentTreeForCategory(
          this.addModal.category.parentCategoryId
        )
          .map((c) => c.name)
          .join(" / ");
      }
      return "";
    },

    departments() {
      return [
        {
          value: "halytysosasto",
          label: "Hälytysosasto",
          disabled: !this.$hasRole("admin"),
        },
        {
          value: "naisosasto",
          label: "Tukiosasto",
        },
      ];
    },
    hasEditPermissions() {
      return this.$isSuperAdmin;
    },
  },
  methods: {
    async reloadAll() {
      this.loading = true;
      try {
        const result = (await api.tiedostopankkiGetFilesAndCategories()).data;
        this.categories = result.categories
          .filter((c) => {
            if (c.departments) {
              return (
                this.$isAdmin ||
                (this.$isUser && c.departments.includes("halytysosasto")) ||
                (this.$isNaisosasto && c.departments.includes("naisosasto"))
              );
            }
            return true;
          })
          .map((c) => {
            c._loadingCategoryDelete = false;
            return c;
          });
        this.files = result.files
          .filter((c) => {
            if (c.departments) {
              return (
                this.$isAdmin ||
                (this.$isUser && c.departments.includes("halytysosasto")) ||
                (this.$isNaisosasto && c.departments.includes("naisosasto"))
              );
            }
            return true;
          })
          .map((file) => {
            return {
              ...file,
              createdAtDayJs: dayjs(file.createdAt),
              _loadingFileDelete: false,
            };
          });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    getCategory(category) {
      (category.type = "category"),
        (category.children = [
          ...orderBy(
            this.categories
              .filter((c) => c.parentCategoryId === category.id)
              .flatMap((c) => this.getCategory(c)),
            "name"
          ),

          ...orderBy(
            this.files
              .filter((file) => file.categoryId === category.id)
              .filter((file) => {
                let r = true;
                if (this.filters.year) {
                  r = this.filters.year.some(
                    (y) => y === file.createdAtDayJs.format("YYYY")
                  );
                }
                return r;
              })
              .map((file) => {
                file.type = "file";
                file.name = file.filename;
                return file;
              }),
            "name"
          ),
        ]);
      return category;
    },
    async confirmDeleteFile(file) {
      if (
        await this.$confirm("Haluatko varmasti poistaa tiedoston?", {
          buttonTrueText: "Poista",
          buttonFalseText: "Peruuta",
          color: "error",
          title: file.filename,
        })
      ) {
        file._loadingFileDelete = true;
        try {
          await api.tiedostopankkiDeleteFile({
            key: file.key,
            id: file.id,
          });
          await this.reloadAll();
          this.$toast("Tiedosto poistettu", {
            type: "success",
          });
        } catch (e) {
          console.error(e);
          this.$toast("Tiedoston poistaminen epäonnistui", {
            type: "error",
          });
          file._loadingFileDelete = false;
        }
      }
    },
    async confirmDeleteCategory(category) {
      if (
        await this.$confirm(
          "Haluatko varmasti poistaa kansion? Myös kaikki kansion tiedostot poistetaan.",
          {
            buttonTrueText: "Poista",
            buttonFalseText: "Peruuta",
            color: "error",
            title: category.name,
          }
        )
      ) {
        category._loadingCategoryDelete = true;
        try {
          await api.tiedostopankkiDeleteCategory({
            id: category.id,
          });
          await this.reloadAll();
          this.$toast("Kansio poistettu", {
            type: "success",
          });
        } catch (e) {
          console.error(e);
          this.$toast("Kansion poistaminen epäonnistui", {
            type: "error",
          });
          category._loadingCategoryDelete = false;
        }
      }
    },
    hasDeletePermission(file) {
      return this.$isSuperAdmin;
      /*return (
        this.$isAdmin ||
        (file.departments.includes("naisosasto") &&
          file.departments.length === 1 &&
          this.$isNaisosasto)
      );*/
    },
    getFileIcon(extension) {
      const icons = {
        pdf: "mdi-file-document",
        jpg: "mdi-file-image",
        jpeg: "mdi-file-image",
        png: "mdi-file-image",
        gif: "mdi-file-image",
        bmp: "mdi-file-image",
        mp3: "mdi-file-music",
        wav: "mdi-file-music",
        mp4: "mdi-file-video",
        mov: "mdi-file-video",
        avi: "mdi-file-video",
        doc: "mdi-file-word",
        docx: "mdi-file-word",
        xls: "mdi-file-excel",
        xlsx: "mdi-file-excel",
        ppt: "mdi-file-powerpoint",
        pptx: "mdi-file-powerpoint",
        // Add more file types and icons as needed
      };

      // Return the icon name if found; otherwise, return a default file icon
      return icons[extension.toLowerCase()] || "mdi-file";
    },
    getParentTreeForCategory(id) {
      const parent = this.categories.find((c) => c.id === id);
      if (parent) {
        return this.getParentTreeForCategory(parent.parentCategoryId).concat(
          parent
        );
      }
      return [];
    },
    async openFile(file) {
      try {
        const theBlob = await getBlob(ref(tiedostopankkiStorage, file.key));
        downloadjs(theBlob, file.filename, file.fileType);
      } catch (error) {
        console.error(error);
        this.$toast("Tiedostoa ei voitu avata. Yritä uudelleen.", {
          type: "error",
        });
      }
    },
    async saveCategory() {
      this.loadingCategorySave = true;

      try {
        await api.tiedostopankkiAddCategory({
          name: this.addModal.category.name,
          description: this.addModal.category.description || "",
          parentCategoryId: this.addModal.category.parentCategoryId,
          departments: this.addModal.category.departments,
        });
        this.$toast("Kansio tallennettu.", {
          type: "success",
        });
        this.addModal.category.name = "";
        this.addModal.category.description = "";

        this.reloadAll();
      } catch (e) {
        this.$toast("Tallennus epäonnistui. Ole hyvä ja yritä uudelleen.", {
          type: "error",
        });
        console.error(e);
      }

      this.loadingCategorySave = false;
    },
    async saveFiles() {
      this.loadingFileSave = true;
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.addModal.file.categoryId) {
        this.$toast("Ei kansiota. Kansio tulee olla valittu.", {
          type: "error",
        });
        return;
      }
      const files = await this.$refs.upload?.getFiles();

      try {
        if (files) {
          for (let filesKey in files) {
            (
              await api.tiedostopankkiAddFile({
                categoryId: this.addModal.file.categoryId,
                description: this.addModal.file.description || "",
                //name: this.addModal.file.name || "Tiedosto",
                departments: this.addModal.file.departments,
                ...files[filesKey],
              })
            ).data;
          }
        }

        this.$toast("Tiedosto tallennettu.", {
          type: "success",
        });
        this.addModal.file.description = "";
        this.$refs.upload?.reset();
        this.reloadAll();
      } catch (e) {
        this.$toast("Tallennus epäonnistui. Ole hyvä ja yritä uudelleen.", {
          type: "error",
        });
        console.error(e);
      }
      this.loadingFileSave = false;
    },
    changeAddModalFileNameIfEmpty() {
      if (this.addModal.file.name === "" && this.$refs.upload?.files?.[0]) {
        this.addModal.file.name = this.$refs.upload.files?.[0]?.filename;
      }
    },
  },
  mounted() {
    this.reloadAll();
  },
  watch: {
    showAddModal() {
      if (!this.showAddModal) {
        this.$refs.form.reset();
      }

      const parentCategory = this.categories.find(
        (c) => c.id === this.addModal.category.parentCategoryId
      );
      if (parentCategory) {
        this.addModal.category.departments = parentCategory.departments;
        this.addModal.file.departments = parentCategory.departments;
      } else {
        this.addModal.category.departments = [
          this.$isAdmin && "halytysosasto",
          !this.$isAdmin && this.$isNaisosasto && "naisosasto",
        ].filter(Boolean);
        this.addModal.file.departments = [
          this.$isAdmin && "halytysosasto",
          !this.$isAdmin && this.$isNaisosasto && "naisosasto",
        ].filter(Boolean);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
