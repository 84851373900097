var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-simple-table',[_c('tbody',_vm._l((_vm.harjoitukset),function(harjoitus){return _c('tr',{key:_vm.$id(harjoitus),class:[
          _vm.$id(_vm.nextHarjoitus) === _vm.$id(harjoitus) &&
            'blue lighten-5 font-weight-bold',
        ]},[_c('td',{class:[_vm.notRegular(harjoitus) && 'red--text']},[_vm._v(" "+_vm._s(_vm.$formatWeekDayDateAndHourMinute(harjoitus.date))+" ")]),_c('td',[_vm._v(_vm._s(harjoitus.subject))]),_c('td',[_vm._v(_vm._s(harjoitus.type))]),_c('td',[_vm._v(_vm._s(harjoitus.location))]),_c('td',[_vm._v(_vm._s(harjoitus.info))])])}),0)]):_c('v-list',[_vm._l((_vm.harjoitukset),function(harjoitus,i){return [_c('v-list-item',{key:_vm.$id(harjoitus),class:[
          _vm.$id(_vm.nextHarjoitus) === _vm.$id(harjoitus) &&
            'blue lighten-5 font-weight-bold',
        ]},[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{class:[_vm.notRegular(harjoitus) && 'red--text']},[_vm._v(" "+_vm._s(_vm.$formatWeekDayDateAndHourMinute(harjoitus.date))+" ")])]),_c('v-list-item-subtitle',{staticClass:"subtitle-1 text-wrap"},[_vm._v(_vm._s(harjoitus.subject)+" ")]),_c('div',{staticClass:"subtitle-2 text-wrap"},[_vm._v(" "+_vm._s(harjoitus.type)+" "+_vm._s(harjoitus.location)+" "+_vm._s(harjoitus.info)+" ")])],1)],1),_c('v-divider',{key:i})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }