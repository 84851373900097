<template>
  <div>
    <v-btn class="refresh--button" @click="reload"><span>Lataa</span></v-btn>
  </div>
</template>

<script>
export default {
  name: "RefreshButton",
  methods: {
    reload() {
      localStorage.setItem("swenv", window.swenv);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.refresh--toast .Vue-Toastification__close-button {
  opacity: 1;
}

.refresh--button {
  .v-btn__content {
    color: #000 !important;
  }

  &:hover {
    .v-btn__content {
      color: #000 !important;
    }
  }
}
</style>
