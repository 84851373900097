<template>
  <basic-page
    :fallback="{ name: 'Ilmoitustaulu' }"
    force-fallback
    prev
    title="Ilmoitus"
  >
    <v-row class="fill-height">
      <v-col cols="12" lg="8" offset-lg="2" offset-xl="3" xl="6">
        <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
        <v-form ref="form">
          <v-card v-if="!loading">
            <v-card-title
              ><span v-if="ilmoitus.id">Muokkaa ilmoitusta</span
              ><span v-else>Uusi ilmoitus</span>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="ilmoitus.title"
                    :rules="[$rules.required]"
                    label="Otsikko"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="ilmoitus.content"
                    auto-grow
                    label="Sisältö"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="4">
                  <modal-picker
                    v-model="ilmoitus.dateStart"
                    label="Aloituspäivämäärä"
                  />
                </v-col>
                <v-col cols="12" lg="3">
                  <modal-picker
                    v-model="ilmoitus.timeStart"
                    label="Aloitusaika"
                    type="time"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="4">
                  <modal-picker
                    v-model="ilmoitus.dateEnd"
                    :error="diff < 0"
                    label="Lopetuspäivämäärä"
                  />
                </v-col>
                <v-col cols="12" lg="3">
                  <modal-picker
                    v-model="ilmoitus.timeEnd"
                    :error="diff < 0"
                    label="Lopetusaika"
                    type="time"
                  />
                </v-col>
                <v-col v-if="diff < 0" cols="12">
                  <v-alert type="error"
                    >Lopetusajan pitää olla aloitusajan jälkeen
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-select
                    v-model="ilmoitus.type"
                    :items="types"
                    item-text="label"
                    item-value="value"
                    label="Tyyppi"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="ilmoitus.departments"
                    :items="departments"
                    multiple
                    item-text="label"
                    item-value="value"
                    label="Osastot"
                    outlined
                  ></v-select>
                </v-col>
                <v-col v-if="ilmoitus.id" cols="12">
                  <file-upload ref="upload"></file-upload>
                  <template v-for="(file, i) in ilmoitus.files">
                    <v-list-item-subtitle
                      :key="i"
                      class="text-wrap info--text d-flex align-center mb-3 cursor-pointer"
                      @click="openFile(file)"
                    >
                      <v-icon>mdi mdi-paperclip</v-icon>
                      <div>{{ file.filename }}</div>
                      <v-btn
                        :loading="loadingDelete"
                        icon
                        @click.stop="deleteFile(file)"
                      >
                        <v-icon color="error"> mdi mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-subtitle>
                  </template>
                </v-col>
              </v-row>
              <v-divider v-if="ilmoitus.type === 'ilmoittautuminen'" />
              <v-row v-if="ilmoitus.type === 'ilmoittautuminen'">
                <v-col class="mt-6" cols="12">
                  <h3>Ilmoittautumisen asetukset</h3>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ilmoitus.participantCount"
                    hint="Jätä tyhjäksi mikäli et halua rajoittaa osallistujamäärää."
                    label="Max osallistujamäärä"
                    outlined
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="$id(ilmoitus)"
                :loading="loadingDelete"
                color="error"
                @click="deleteIlmoitus"
                >Poista
              </v-btn>
              <v-btn :loading="loadingSave" color="primary" @click="save"
                >Tallenna
              </v-btn>
            </v-card-actions>
            <v-divider />
            <v-card-text v-if="$id(ilmoitus)">
              <ilmoittautuminen
                :ilmoitus="ilmoitus"
                admin
                @reload="reloadIlmoitus"
              />
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import Ilmoittautuminen from "@/components/Ilmoitustaulu/Ilmoittautuminen";
import FileUpload from "@/components/FileUpload";
import api from "@/api";
import ModalPicker from "@/components/ModalPicker.vue";
import dayjs from "dayjs";

export default {
  name: "Ilmoitus",
  components: { ModalPicker, FileUpload, Ilmoittautuminen, BasicPage },
  data() {
    return {
      ilmoitus: {
        title: null,
        content: null,
        type: "ilmoitus",
        participantCount: null,
        departments: [],
        dateStart: null,
        timeStart: null,
        dateEnd: null,
        timeEnd: null,
      },
      types: [
        {
          value: "ilmoitus",
          label: "Ilmoitus",
        },
        {
          value: "ilmoittautuminen",
          label: "Ilmoittautuminen",
        },
      ],
      files: [],
      loading: false,
      loadingSave: false,
      loadingDelete: false,
    };
  },
  computed: {
    diff() {
      if (
        this.ilmoitus.dateStart &&
        this.ilmoitus.timeStart &&
        this.ilmoitus.dateEnd &&
        this.ilmoitus.timeEnd
      ) {
        const startTime = dayjs(
          this.ilmoitus.date + " " + this.ilmoitus.time,
          "YYYY-MM-DD HH:mm"
        );
        const returnTime = dayjs(
          this.ilmoitus.dateReturn + " " + this.ilmoitus.timeReturn,
          "YYYY-MM-DD HH:mm"
        );
        return returnTime.diff(startTime);
      }
      return null;
    },
    departments() {
      return [
        {
          value: "halytysosasto",
          label: "Hälytysosasto",
          disabled: !this.$hasRole("admin"),
        },
        {
          value: "naisosasto",
          label: "Tukiosasto",
        },
      ];
    },
    id() {
      return this.$id(this.ilmoitus);
    },
  },
  methods: {
    async save() {
      this.loadingSave = true;
      if (!this.$refs.form.validate()) {
        return;
      }
      const files = await this.$refs.upload?.getFiles();

      try {
        const ilmoitus = (await api.ilmoitusUpsert(this.ilmoitus)).data;

        if (files) {
          for (let filesKey in files) {
            (
              await api.ilmoitusAddFile({
                ilmoitusId: ilmoitus.id,
                ...files[filesKey],
              })
            ).data;
          }
        }

        this.$toast("Ilmoitus tallennettu.", {
          type: "success",
        });
        if (!this.id) {
          this.$router.push({
            name: "IlmoitusById",
            params: { id: this.$id(ilmoitus) },
          });
        }
        this.ilmoitus = ilmoitus;
        this.reloadIlmoitus();
      } catch (e) {
        this.$toast("Tallennus epäonnistui. Ole hyvä ja yritä uudelleen.", {
          type: "error",
        });
        console.error(e);
      }
      this.loadingSave = false;
    },
    async deleteFile(file) {
      this.loadingDeleteFile = true;
      try {
        await api.ilmoitusDeleteFile(file);
        await this.reloadIlmoitus();
        this.$toast("Tiedosto poistettu", {
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$toast("Tiedoston poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDelete = false;
    },
    async reloadIlmoitus() {
      this.loading = true;
      const ilmoitus = (await api.ilmoitusGet({ id: this.$route.params.id }))
        .data;
      if (
        ilmoitus.participants?.findIndex((il) => il.sub === this.$user.uid) > -1
      ) {
        ilmoitus._participant = true;
      }
      this.ilmoitus = ilmoitus;
      this.loading = false;
    },
    async deleteIlmoitus() {
      this.loadingDelete = true;
      try {
        await api.ilmoitusDelete(this.ilmoitus);
        this.$toast("Ilmoitus poistettu", {
          type: "success",
        });
        this.$router.push({ name: "Ilmoitustaulu" });
      } catch (e) {
        console.error(e);
        this.$toast("Poistaminen epäonnistui", {
          type: "error",
        });
      }
      this.loadingDelete = false;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.reloadIlmoitus();
    } else {
      this.ilmoitus.departments = [
        this.$isAdmin && "halytysosasto",
        !this.$isAdmin && this.$isNaisosasto && "naisosasto",
      ].filter(Boolean);
    }
  },
};
</script>

<style lang="scss" scoped></style>
