const tehtavaluokat = [
  {
    code: "03",
    label: "Pahoinpitely, tappelu",
    header: !0,
  },
  {
    code: "031",
    label: "Ampuminen",
  },
  {
    code: "032",
    label: "Puukotus",
  },
  {
    code: "033",
    label: "Potkiminen / hakkaaminen",
  },
  {
    code: "034",
    label: "Pahoinpitely: tekotapa ep\xe4selv\xe4",
  },
  {
    code: "06",
    label: "Viranomainen uhattuna",
    header: !0,
  },
  {
    code: "066",
    label: "Viranomainen uhattuna: h\xe4t\xe4kutsu VIRVE -radiolla",
  },
  {
    code: "10",
    label: "Ilmoitinlaiteilmoitus",
    header: !0,
  },
  {
    code: "103",
    label: "Automaattinen paloh\xe4lytys",
  },
  {
    code: "104",
    label: "Ilmoitinlaiteh\xe4lytys: s\xe4teilyh\xe4lytys",
  },
  {
    code: "105",
    label: "Ilmoitinlaiteh\xe4lytys: hissih\xe4lytys",
  },
  {
    code: "106",
    label: "Ilmoitinlaiteh\xe4lytys: laitevika",
  },
  {
    code: "107",
    label: "Ilmoitinlaiteh\xe4lytys: yhteysvika",
  },
  {
    code: "108",
    label: "Ilmoitinlaiteh\xe4lytys: huolto",
  },
  {
    code: "20",
    label: "Tieliikenneonnettomuus",
    header: !0,
  },
  {
    code: "200",
    label: "Tieliikenneonnettomuus: muu tai onnettomuuden uhka",
  },
  {
    code: "201",
    label: "Tieliikenneonnettomuus: peltikolari, suistuminen",
  },
  {
    code: "202",
    label: "Tieliikenneonnettomuus: pieni",
  },
  {
    code: "203",
    label: "Tieliikenneonnettomuus: keskisuuri",
  },
  {
    code: "204",
    label: "Tieliikenneonnettomuus: suuri",
  },
  {
    code: "205",
    label:
      "Tieliikenneonnettomuus: el\xe4in osallisena (ei henkil\xf6vahinkoja)",
  },
  {
    code: "206",
    label: "Tieliikenneonnettomuus maan alla: pieni",
  },
  {
    code: "207",
    label: "Tieliikenneonnettomuus maan alla: keskisuuri",
  },
  {
    code: "208",
    label: "Tieliikenneonnettomuus maan alla: suuri",
  },
  {
    code: "21",
    label: "Raideliikenneonnettomuus",
    header: !0,
  },
  {
    code: "210",
    label: "Raideliikenneonnettomuus: muu",
  },
  {
    code: "211",
    label: "Raideliikenneonnettomuus: peltikolari",
  },
  {
    code: "212",
    label: "Raideliikenneonnettomuus: pieni",
  },
  {
    code: "213",
    label: "Raideliikenneonnettomuus: keskisuuri",
  },
  {
    code: "214",
    label: "Raideliikenneonnettomuus: suuri",
  },
  {
    code: "215",
    label: "Raideliikenneonnettomuus: el\xe4in osallisena",
  },
  {
    code: "216",
    label: "Raideliikenneonnettomuus maan alla: pieni",
  },
  {
    code: "217",
    label: "Raideliikenneonnettomuus maan alla: keskisuuri",
  },
  {
    code: "218",
    label: "Raideliikenneonnettomuus maan alla: suuri",
  },
  {
    code: "22",
    label: "Vesiliikenneonnettomuus",
    header: !0,
  },
  {
    code: "220",
    label: "Vesiliikenneonnettomuus: Muu",
  },
  {
    code: "221",
    label: "Vesiliikenneonnettomuus: pieni",
  },
  {
    code: "222",
    label: "Vesiliikenneonnettomuus: keskisuuri",
  },
  {
    code: "223",
    label: "Vesiliikenneonnettomuus: suuri",
  },
  {
    code: "23",
    label: "Ilmaliikenneonnettomuus tai -vaara",
    header: !0,
  },
  {
    code: "231",
    label: "Ilmaliikenneonnettomuus: pieni",
  },
  {
    code: "232",
    label: "Ilmaliikenneonnettomuus: keskisuuri",
  },
  {
    code: "233",
    label: "Ilmaliikenneonnettomuus: suuri",
  },
  {
    code: "234",
    label: "Ilmaliikenneonnettomuusvaara: pieni",
  },
  {
    code: "235",
    label: "Ilmaliikenneonnettomuusvaara: keskisuuri",
  },
  {
    code: "236",
    label: "Ilmaliikenneonnettomuusvaara: suuri",
  },
  {
    code: "27",
    label: "Maastoliikenneteht\xe4v\xe4",
    header: !0,
  },
  {
    code: "270",
    label: "Maastoliikenneteht\xe4v\xe4: muu",
  },
  {
    code: "271",
    label: "Maastoliikenneonnettomuus",
  },
  {
    code: "40",
    label: "Rakennuspalo",
    header: !0,
  },
  {
    code: "401",
    label: "Rakennuspalo: pieni",
  },
  {
    code: "402",
    label: "Rakennuspalo: keskisuuri",
  },
  {
    code: "403",
    label: "Rakennuspalo: suuri",
  },
  {
    code: "404",
    label: "Rakennuspalo maan alla: pieni",
  },
  {
    code: "405",
    label: "Rakennuspalo maan alla: keskisuuri",
  },
  {
    code: "406",
    label: "Rakennuspalo maan alla: suuri",
  },
  {
    code: "41",
    label: "Liikennev\xe4linepalo",
    header: !0,
  },
  {
    code: "411",
    label: "Liikennev\xe4linepalo: pieni",
  },
  {
    code: "412",
    label: "Liikennev\xe4linepalo:keskisuuri",
  },
  {
    code: "413",
    label: "Liikennev\xe4linepalo:suuri",
  },
  {
    code: "42",
    label: "Maastopalo",
    header: !0,
  },
  {
    code: "420",
    label: "savuhavainto/tarkastusteht\xe4v\xe4",
  },
  {
    code: "421",
    label: "Maastopalo : pieni",
  },
  {
    code: "422",
    label: "Maastopalo : keskisuuri",
  },
  {
    code: "423",
    label: "Maastopalo : suuri",
  },
  {
    code: "424",
    label: "Turvetuotantoaluepalo: pieni",
  },
  {
    code: "425",
    label: "Turvetuotantoaluepalo: keskisuuri",
  },
  {
    code: "426",
    label: "Turvetuotantoaluepalo: suuri",
  },
  {
    code: "43",
    label: "Tulipalo, muu",
    header: !0,
  },
  {
    code: "431",
    label: "Tulipalo muu : pieni",
  },
  {
    code: "432",
    label: "Tulipalo muu : keskisuuri",
  },
  {
    code: "433",
    label: "Tulipalo muu : suuri",
  },
  {
    code: "434",
    label: "Tulipalo muu, maan alla: pieni",
  },
  {
    code: "435",
    label: "Tulipalo muu, maan alla: keskisuuri",
  },
  {
    code: "436",
    label: "Tulipalo muu, maan alla: suuri",
  },
  {
    code: "44",
    label: "R\xe4j\xe4hdys / sotruma",
    header: !0,
  },
  {
    code: "441",
    label: "R\xe4j\xe4hdys / sortuma: pieni",
  },
  {
    code: "442",
    label: "R\xe4j\xe4hdys / sortuma: keskisuuri",
  },
  {
    code: "443",
    label: "R\xe4j\xe4hdys / sortuma: suuri",
  },
  {
    code: "444",
    label: "R\xe4j\xe4hdys- / sortumavaara",
  },
  {
    code: "45",
    label: "Vaarallisen aineen onnettomuus",
    header: !0,
  },
  {
    code: "451",
    label: "Vaarallisen aineen onnettomuus: pieni",
  },
  {
    code: "452",
    label: "Vaarallisen aineen onnettomuus: keskisuuri",
  },
  {
    code: "453",
    label: "Vaarallisen aineen onnettomuus: suuri",
  },
  {
    code: "455",
    label: "Vaarallisen aineen onnettomuus: vaara",
  },
  {
    code: "456",
    label: "Ydinvoimalaitos: varautumistilanne",
  },
  {
    code: "457",
    label: "Ydinvoimalaitos: laitosh\xe4t\xe4tilanne",
  },
  {
    code: "458",
    label: "Ydinvoimalaitos: yleish\xe4t\xe4tilanne",
  },
  {
    code: "46",
    label: "Vahingontorjunta",
    header: !0,
  },
  {
    code: "461",
    label: "Vahingontorjunta: pieni",
  },
  {
    code: "462",
    label: "Vahingontorjunta: keskisuuri",
  },
  {
    code: "463",
    label: "Vahingontorjunta: suuri",
  },
  {
    code: "47",
    label: "\xd6ljyvahinko",
    header: !0,
  },
  {
    code: "471",
    label: "\xd6ljyvahinko / ymp\xe4rist\xf6onnettomuus: maalla, pieni",
  },
  {
    code: "472",
    label: "\xd6ljyvahinko / ymp\xe4rist\xf6onnettomuus: maalla, keskisuuri",
  },
  {
    code: "473",
    label: "\xd6ljyvahinko / ymp\xe4rist\xf6onnettomuus: maalla, suuri",
  },
  {
    code: "474",
    label:
      "\xd6ljyvahinko / ymp\xe4rist\xf6onnettomuus: vesist\xf6ss\xe4, pieni",
  },
  {
    code: "475",
    label:
      "\xd6ljyvahinko / ymp\xe4rist\xf6onnettomuus: vesist\xf6ss\xe4, keskisuuri",
  },
  {
    code: "476",
    label:
      "\xd6ljyvahinko / ymp\xe4rist\xf6onnettomuus: vesist\xf6ss\xe4, suuri",
  },
  {
    code: "477",
    label: "\xd6ljyvahinko / ymp\xe4rist\xf6onnettomuus: vaara",
  },
  {
    code: "48",
    label: "Ihmisen pelastaminen",
    header: !0,
  },
  {
    code: "480",
    label: "Ihmisen pelastaminen: Muu",
  },
  {
    code: "481",
    label: "Ihmisen pelastaminen: Etsint\xe4",
  },
  {
    code: "482",
    label: "Ihmisen pelastaminen: Avunanto",
  },
  {
    code: "483",
    label: "Ihmisen pelastaminen: Vedest\xe4",
  },
  {
    code: "484",
    label: "Ihmisen pelastaminen: Pintapelastus",
  },
  {
    code: "485",
    label: "Ihmisen pelastaminen: Maastosta",
  },
  {
    code: "486",
    label: "Ihmisen pelastaminen: Puristuksista",
  },
  {
    code: "487",
    label: "Ihmisen pelastaminen: ylh\xe4\xe4lt\xe4/Alhaalta",
  },
  {
    code: "49",
    label: "Onnettomuustilanne, muu",
    header: !0,
  },
  {
    code: "490",
    label:
      "Onnettomuustilanne maanp\xe4\xe4ll\xe4 (ep\xe4selv\xe4 tilanne, koodi tarkentuu)",
  },
  {
    code: "414",
    label: "Liikennev\xe4linepalo maan alla: pieni",
  },
  {
    code: "415",
    label: "Liikennev\xe4linepalo maan alla: keskisuuri",
  },
  {
    code: "416",
    label: "Liikennev\xe4linepalo maan alla: suuri",
  },
  {
    code: "55",
    label: "Avunanto",
    header: !0,
  },
  {
    code: "550",
    label: "Avunanto: muu",
  },
  {
    code: "551",
    label: "Avunanto: virka-apu toiselle viranomaiselle",
  },
  {
    code: "552",
    label: "Avunanto: tarkistus tms. teht\xe4v\xe4",
  },
  {
    code: "553",
    label: "Avunanto: uhka- / varuillaoloteht\xe4v\xe4",
  },
  {
    code: "58",
    label: "El\xe4inteht\xe4v\xe4",
    header: !0,
  },
  {
    code: "580",
    label: "El\xe4inteht\xe4v\xe4: muu",
  },
  {
    code: "581",
    label: "El\xe4inteht\xe4v\xe4: el\xe4imen pelastaminen",
  },
  {
    code: "70",
    label: "Peruselintoiminnan h\xe4iri\xf6",
    header: !0,
  },
  {
    code: "700",
    label: "Eloton",
  },
  {
    code: "701",
    label: "Elvytys",
  },
  {
    code: "702",
    label: "Tajuttomuus",
  },
  {
    code: "703",
    label: "Hengitysvaikeus",
  },
  {
    code: "704",
    label: "Rintakipu",
  },
  {
    code: "705",
    label: "Rytmih\xe4iri\xf6",
  },
  {
    code: "706",
    label: "Aivohalvaus",
  },
  {
    code: "71",
    label: "Hapenpuute",
    header: !0,
  },
  {
    code: "711",
    label: "Ilmatie-este",
  },
  {
    code: "713",
    label: "Hirtt\xe4ytyminen, Kuristuminen",
  },
  {
    code: "714",
    label: "Hukkuminen",
  },
  {
    code: "74",
    label: "Vamma (muu mekaaninen)",
    header: !0,
  },
  {
    code: "741",
    label: "Putoaminen",
  },
  {
    code: "744",
    label: "Haava",
  },
  {
    code: "745",
    label: "Kaatuminen",
  },
  {
    code: "746",
    label: "Isku",
  },
  {
    code: "747",
    label: "Vamma: muu",
  },
  {
    code: "75",
    label: "Onnettomuus (ei mekaaninen)",
    header: !0,
  },
  {
    code: "751",
    label: "Kaasumyrkytys",
  },
  {
    code: "752",
    label: "Myrkytys",
  },
  {
    code: "753",
    label: "S\xe4hk\xf6isku",
  },
  {
    code: "754",
    label: "Palovamma",
  },
  {
    code: "755",
    label: "Palovamma, l\xe4mp\xf6halvaus",
  },
  {
    code: "756",
    label: "Paleltuminen, alil\xe4mp\xf6isyys",
  },
  {
    code: "757",
    label: "Onnettomuus, muu",
  },
  {
    code: "76",
    label: "Verenvuoto (ilman vammaa)",
    header: !0,
  },
  {
    code: "761",
    label: "Verenvuoto: suusta",
  },
  {
    code: "762",
    label: "Verenvuoto: gynekologinen/urologinen",
  },
  {
    code: "763",
    label: "Verenvuoto: korva/Nen\xe4",
  },
  {
    code: "764",
    label: "S\xe4\xe4rihaava/Muu",
  },
  {
    code: "77",
    label: "Sairaus (liittyy l\xf6yd\xf6s)",
    header: !0,
  },
  {
    code: "770",
    label: "Sairauskohtaus",
  },
  {
    code: "771",
    label: "Sokeritasapainon h\xe4iri\xf6",
  },
  {
    code: "772",
    label: "Kouristelu",
  },
  {
    code: "773",
    label: "Yliherkkyysreaktio",
  },
  {
    code: "774",
    label: "Muu sairastuminen",
  },
  {
    code: "775",
    label: "Oksentelu, ripuli",
  },
  {
    code: "78",
    label: "Sairaus (ilmenee oireena)",
    header: !0,
  },
  {
    code: "781",
    label: "Vatsakipu",
  },
  {
    code: "782",
    label: "P\xe4\xe4-/ niskas\xe4rky",
  },
  {
    code: "783",
    label: "Selk\xe4-/ raaja-/ vartalokipu",
  },
  {
    code: "785",
    label: "Mielenterveysongelma",
  },
  {
    code: "79",
    label: "Sairaankuljetusteht\xe4v\xe4",
    header: !0,
  },
  {
    code: "790",
    label: "H\xe4lytys puhelun aikana",
  },
  {
    code: "791",
    label: "Synnytys",
  },
  {
    code: "792",
    label: "Varallaolo, valmiussiirto",
  },
  {
    code: "793",
    label: "Hoitolaitossiirto",
  },
  {
    code: "794",
    label: "Muu sairaankuljetusteht\xe4v\xe4 / aikatilausteht\xe4v\xe4",
  },
  {
    code: "796",
    label: "Monipotilastilanne, suuronnettomuus",
  },
  {
    code: "H35",
    label: "Valmius",
    header: !0,
  },
  {
    code: "H351",
    label: "Asemavalmius",
  },
  {
    code: "H352",
    label: "Valmiussiirto",
  },
  {
    code: "X",
    label: "Ei kuljetusta",
    header: !0,
  },
  {
    code: "X-0",
    label: "Tekninen este",
  },
  {
    code: "X-1",
    label: "Kuollut",
  },
  {
    code: "X-2",
    label: "Terveydentila m\xe4\xe4ritetty, ohjattu poliisin suojaan",
  },
  {
    code: "X-3",
    label: "Pyydetty kohteeseen muuta apua",
  },
  {
    code: "X-4",
    label: "Muu kuljetus",
  },
  {
    code: "X-5",
    label:
      "Terveydentila m\xe4\xe4ritetty, ei tarvetta ensihoitoon tai hoito-toimenpiteisiin ",
  },
  {
    code: "X-6",
    label: "Potilas kielt\xe4ytyi",
  },
  {
    code: "X-7",
    label: "Potilasta ei l\xf6ydy",
  },
  {
    code: "X-8",
    label: "Potilas hoidettu kohteessa",
  },
  {
    code: "X-9",
    label: "Teht\xe4v\xe4 peruutettu",
  },
];

export default tehtavaluokat;