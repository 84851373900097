<template>
  <div class="d-flex">
    <!--<div class="mr-2">
      <v-icon v-if="item.type === 'category'"
        >mdi {{ open ? "mdi-folder-open" : "mdi-folder" }}
      </v-icon>
      <v-icon v-if="item.type === 'file'">mdi {{ fileIcon }} </v-icon>
    </div>-->
    <div>
      <span class="text-subtitle-1" v-if="item.type === 'category'">{{
        item.name
      }}</span>
      <div v-if="item.type === 'file'">
        <span
          class="text-subtitle-1 text-wrap info--text d-flex align-center cursor-pointer"
          style="word-break: break-word"
          @click="openFile"
          >{{ item.filename }}</span
        >
        <div class="caption">
          {{ $formatDateAndHourMinute(item.createdAt) }}
        </div>
      </div>
      <div class="caption" v-if="item.description && item.type === 'file'">
        {{ item.description }}
      </div>
      <div class="caption" v-if="item.departments && editMode">
        <template v-for="department in departments">
          <v-chip x-small :key="department">{{ department }}</v-chip>
        </template>
      </div>
      <div class="mb-2"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tiedostopankki-item",
  props: {
    editMode: { type: Boolean, default: false },
    item: { type: Object, required: true },
    openFile: { type: Function, required: true },
    open: { type: Boolean, required: false },
  },
  computed: {
    departments() {
      return this.item.departments.map((department) => {
        if (department === "halytysosasto") return "Hälytysosasto";
        if (department === "naisosasto") return "Tukiosasto";
      });
    },
    fileIcon() {
      return this.getFileIcon(this.item.fileExtension);
    },
  },
  methods: {
    getFileIcon(extension) {
      const icons = {
        pdf: "mdi-file-document",
        jpg: "mdi-file-image",
        jpeg: "mdi-file-image",
        png: "mdi-file-image",
        gif: "mdi-file-image",
        bmp: "mdi-file-image",
        mp3: "mdi-file-music",
        wav: "mdi-file-music",
        mp4: "mdi-file-video",
        mov: "mdi-file-video",
        avi: "mdi-file-video",
        doc: "mdi-file-word",
        docx: "mdi-file-word",
        xls: "mdi-file-excel",
        xlsx: "mdi-file-excel",
        ppt: "mdi-file-powerpoint",
        pptx: "mdi-file-powerpoint",
        // Add more file types and icons as needed
      };

      // Return the icon name if found; otherwise, return a default file icon
      return icons[extension.toLowerCase()] || "mdi-file";
    },
  },
};
</script>
