import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fi from "vuetify/lib/locale/fi";
import VuetifyConfirm from "vuetify-confirm";

Vue.use(Vuetify);
const vuetify = new Vuetify({
  lang: {
    locales: { fi },
    current: "fi",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        error: "#ff4c51",
        anchor: "#6c0000",
        primary: "#6c0000",
        secondary: "#8a8d93",
        accent: "#0d6efd",
        //error: "#ff4c51",
        info: "#16b1ff",
        success: "#56ca00",
        warning: "#ffb400",
      },
    },
  },
});

Vue.use(VuetifyConfirm, { vuetify });
export default vuetify;
