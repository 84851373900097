<template>
  <v-dialog
    ref="dialogModal"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="320px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :clearable="clearable"
        :error="error"
        :label="label"
        hide-details
        outlined
        readonly
        v-bind="attrs"
        v-on="on"
      >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
      </v-text-field>
    </template>
    <v-date-picker
      v-if="type === 'date'"
      v-model="date"
      :allowed-dates="allowedDates"
      header-color="primary"
      scrollable
      show-week
      first-day-of-week="1"
      :full-width="$vuetify.breakpoint.xsOnly"
    >
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="$refs.dialogModal.save(null)">
        Tyhjennä
      </v-btn>
      <v-btn color="primary" text @click="modal = false"> Peruuta</v-btn>
      <v-btn color="primary" text @click="$refs.dialogModal.save(date)">
        OK
      </v-btn>
    </v-date-picker>
    <v-date-picker
      v-if="type === 'week'"
      v-model="date"
      :allowed-dates="allowedDates"
      header-color="primary"
      scrollable
      show-week
      first-day-of-week="1"
    >
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="$refs.dialogModal.save(null)">
        Tyhjennä
      </v-btn>
      <v-btn color="primary" text @click="modal = false"> Peruuta</v-btn>
      <v-btn color="primary" text @click="$refs.dialogModal.save(date)">
        OK
      </v-btn>
    </v-date-picker>
    <v-time-picker v-if="type === 'time'" v-model="date" format="24hr">
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="$refs.dialogModal.save(null)">
        Tyhjennä
      </v-btn>
      <v-btn color="primary" text @click="modal = false"> Peruuta</v-btn>
      <v-btn color="primary" text @click="$refs.dialogModal.save(date)">
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ModalPicker",
  data() {
    return {
      modal: false,
      date: this.value,
    };
  },
  props: {
    value: String,
    allowedDates: {
      type: Function,
    },
    type: {
      type: String,
      default: "date",
    },
    label: String,
    error: Boolean,
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.type === "time") {
        return this.date;
      }
      if (this.type === "week") {
        return this.formatWeek(this.date);
      }
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.$emit("input", this.date);
    },
    value() {
      this.date = this.value;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return dayjs(date).format("DD.MM.YYYY");
    },
    formatWeek(date) {
      if (!date) return null;
      return dayjs(date).format("WW/YYYY");
    },
  },
};
</script>

<style scoped></style>
