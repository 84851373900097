<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        hide-details
        label="Haku"
        single-line
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :disable-pagination="true"
      :headers="headers"
      :items="henkilot"
      :items-per-page="-1"
      :loading="loading"
      :search="search"
    >
      <template v-slot:item.defaultEngine="{ item }">
        <span v-if="item.defaultEngine">{{ getDefaultEngine(item) }}</span>
      </template>
      <template v-slot:item.isYj="{ item }">
        <span v-if="item.isYj">
          <v-simple-checkbox :value="item.isYj" color="primary"
        /></span>
      </template>
      <template v-slot:item.smokediver="{ item }">
        <span v-if="item.smokediver">
          <v-simple-checkbox :value="item.smokediver" />
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-spacer />
        <v-btn icon @click="edit(item)">
          <v-icon>mdi mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="editModal" max-width="600px" persistent>
      <v-card>
        <v-card-title
          >{{ $id(henkilo) ? "Muokkaa henkilö" : "Uusi henkilö" }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container v-if="henkilo">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="henkilo.vacancy"
                  hide-details
                  label="Vakanssinumero"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="henkilo.name"
                  hide-details
                  label="Sukunimi Etunimi"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-select
                  v-model="henkilo.defaultEngine"
                  :items="yksikot"
                  hide-details
                  item-text="name"
                  item-value="id"
                  label="Oletusyksikkö"
                  outlined
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-checkbox
                  v-model="henkilo.isYj"
                  hide-details
                  label="Yksikönjohtaja"
                  outlined
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-checkbox
                  v-model="henkilo.smokediver"
                  hide-details
                  label="Savusukeltaja"
                  outlined
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="editModal = false"> Sulje</v-btn>
          <v-btn :loading="loadingSave" color="primary" text @click="save">
            Tallenna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "HenkilotTable",
  props: {
    henkilot: Array,
    loading: Boolean,
  },
  data() {
    return {
      search: "",
      henkilo: null,
      editModal: false,
      headers: [
        { text: "Vakanssi", value: "vacancy" },
        { text: "Nimi", value: "name" },
        { text: "Oletusyksikkö", value: "defaultEngine" },
        { text: "YJ", value: "isYj" },
        { text: "Savusukeltaja", value: "smokediver" },
        { text: "", value: "actions", width: 100 },
      ],
      loadingSave: false,
    };
  },
  computed: {
    yksikot() {
      return this.$store.state.yksikot;
    },
  },
  methods: {
    getDefaultEngine(item) {
      return this.yksikot?.find((y) => y.id === item.defaultEngine)?.name;
    },
    edit(henkilo) {
      this.henkilo = { ...henkilo };
      this.editModal = true;
    },
    async save() {
      this.loadingSave = true;
      try {
        let response = (await api.henkiloUpsert(this.henkilo)).data;
        this.$toast("Henkilö tallennettu", { type: "success" });
        this.henkilo = null;
        this.editModal = false;
        this.$store.dispatch("loadHenkilot");
      } catch (e) {
        console.error(e);
        this.$toast("Henkilön tallennus ei onnistunut", { type: "error" });
      }

      this.loadingSave = false;
    },
  },
};
</script>

<style></style>