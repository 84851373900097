<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { routes } from "./router";
import RefreshButton from "@/components/RefreshButton";
import app, { getToken, onMessage } from "@/plugins/firebase.app";
import api from "@/api";
import { getMessaging, isSupported } from "firebase/messaging";

let unsubscribePushOnMessage = null;
export default {
  name: "App",
  components: {},

  data: () => {
    return {};
  },
  computed: {
    routes: () => {
      return routes;
    },
  },
  methods: {
    async fetchSwenv() {
      return await fetch("/swenv.js?_v=" + Date.now()).then((r) => r.text());
    },
    async checkForUpdates() {
      if (document.visibilityState === "visible") {
        console.info("[checkForUpdates] App visible. Loading Swenv.js");
        try {
          const swenv = await this.fetchSwenv();
          const savedSwenv = localStorage.getItem("swenv", swenv);
          if (!savedSwenv) {
            console.info(
              "[checkForUpdates] No saved swenv found. Setting it to " + swenv
            );
            localStorage.setItem("swenv", swenv);
            return;
          }
          if (savedSwenv !== swenv) {
            console.info("[checkForUpdates] Needs update. Notifying.");
            this.$toast("Päivitys saatavilla.", {
              closeButton: RefreshButton,
              toastClassName: "refresh--toast",
              timeout: false,
            });
            window.swenv = swenv;
          } else {
            console.info("[checkForUpdates] No need for update");
          }
        } catch (e) {
          console.error("[checkForUpdates] error", e);
        }
      } else {
        console.info("[checkForUpdates] App hidden");
      }
    },
  },
  mounted() {
    document.removeEventListener("visibilitychange", this.checkForUpdates);
    document.addEventListener("visibilitychange", this.checkForUpdates);
    if (unsubscribePushOnMessage) {
      try {
        unsubscribePushOnMessage();
      } catch (e) {
        console.error(e);
      }
    }
    try {
      if (this.$hasRole("user") || this.$hasRole("naisosasto")) {
        if ("Notification" in window) {
          if (!isSupported()) {
            console.error("Firebase messaging not supported");
            return;
          }
          const messaging = getMessaging(app);
          try {
            unsubscribePushOnMessage = onMessage(messaging, (payload) => {
              console.log("Message received. ", payload);
              this.$toast(
                <p>
                  <strong>{payload.data?.title}</strong>
                  <br />
                  {payload.data.body}
                </p>,
                {
                  type: "info",
                  timeout: 10000,
                }
              );
            });
            console.log("onMessage registered");
          } catch (e) {
            console.error("onMessage error", e);
          }
          (async () => {
            console.log("Notification.permission =", Notification.permission);
            if (
              localStorage.getItem("notificationPermissionDenied") === "true"
            ) {
              console.error(
                "User denied notification permission, loaded from localStorage"
              );
              return;
            }

            if (Notification.permission === "default") {
              const response = await this.$confirm(
                "Haluatko vastaanottaa ilmoituksia ilmoitustaululle tulevista ilmoituksista?",
                {
                  buttonTrueText: "Kyllä",
                  buttonFalseText: "Ei",
                  title: "Ilmoitukset",
                  color: "primary",
                  persistent: true,
                }
              );
              if (!response) {
                console.error("User denied notification permission");
                localStorage.setItem("notificationPermissionDenied", "true");
                return false;
              }
            }
            Notification.requestPermission()
              .then(async (permission) => {
                if (permission === "granted") {
                  console.log("Notification permission granted.");
                  const serviceWorkerRegistration =
                    await navigator.serviceWorker.getRegistration();
                  return getToken(messaging, {
                    vapidKey:
                      "BCZ3S_HG6b-bUNUJkxygFPTu_UDHfthcEcFmCODFxCTVNNVNRbZtRhYWcNnK7DMkTWAfLEopWOynqpOb_pcJyi0",
                    serviceWorkerRegistration: serviceWorkerRegistration,
                  });
                } else if (permission === "denied") {
                  console.log(
                    "DENIED! Unable to get permission to notify.",
                    permission
                  );
                  throw Error("Unable to get permission to notify.");
                } else {
                  console.log(
                    "Unable to get permission to notify.",
                    permission
                  );
                  throw Error("Unable to get permission to notify.");
                }
              })
              .then(async (token) => {
                if (token) {
                  console.log("Firebase Messaging Token:", token);
                  try {
                    await api.firebaseMessagingAddToken({
                      token,
                      userAgent: navigator.userAgent,
                    });
                  } catch (e) {
                    console.error("firebaseMessagingAddToken error", e);
                  }
                } else {
                  console.log("No token received");
                }

                // Send this token to your server for future use
              })
              .catch((err) => {
                console.log("Unable to get permission to notify.", err);
              });
          })();
        }
      }
    } catch (e) {
      console.error("firebaseMessaging error", e);
    }
  },
  beforeDestroy() {
    if (unsubscribePushOnMessage) {
      unsubscribePushOnMessage();
    }
    document.removeEventListener("visibilitychange", this.checkForUpdates);
  },
};
</script>

<style lang="scss">
@import "./main";

.Vue-Toastification__container.top-right {
  top: 64px;
}

.nav-bar {
  background-color: var(--v-error-base);
}
</style>
