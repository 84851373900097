<template>
  <v-row class="mb-5 mt-5" no-gutters>
    <v-col
      :class="[$vuetify.breakpoint.smAndDown && 'flex-column']"
      class="d-flex"
      cols="12"
    >
      <prev-button
        v-if="step > 1"
        :disabled="loading"
        @click="changeStep(-1)"
      />
      <v-spacer></v-spacer>

      <next-button
        v-if="step < 8"
        :class="[$vuetify.breakpoint.smAndDown && 'mt-3']"
        :disabled="loading"
        color="primary"
        @click="changeStep(+1)"
      />
      <v-btn
        v-if="step === 8"
        :block="$vuetify.breakpoint.smAndDown"
        :class="[$vuetify.breakpoint.smAndDown && 'mt-3']"
        :loading="loading"
        color="success"
        @click="save"
      >
        <v-icon>mdi mdi-send</v-icon>
        Lähetä
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import NextButton from "@/components/Vaaratilanne/NextButton";
import PrevButton from "@/components/Vaaratilanne/PrevButton";

export default {
  name: "Buttons",
  components: { PrevButton, NextButton },
  props: {
    step: Number,
    loading: Boolean,
  },
  methods: {
    save() {
      this.$emit("save");
    },
    changeStep(amount) {
      this.$emit("change-step", amount);
    },
  },
  watch: {},
};
</script>

<style scoped></style>