<template>
  <div class="fill-height">
    <v-navigation-drawer
      :value="drawer"
      app
      color="primary"
      dark
      @input="(show) => $store.dispatch('setDrawer', show)"
    >
      <template v-slot:prepend>
        <v-row>
          <v-col
            class="d-flex flex-column justify-center align-center"
            cols="12"
          >
            <v-img
              contain
              height="150"
              max-width="85%"
              src="https://noormarkunvpk.fi/wp-content/uploads/2017/03/logo.png"
            />
            <h1 style="color: #ffffff">Noormarkun VPK</h1>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </template>

      <v-list dense nav>
        <v-list-item
          v-for="route in routes"
          :key="route.name"
          :to="route.path"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider v-if="$isAdmin" />
      <v-list dense nav>
        <v-list-item
          v-for="route in systemRoutes"
          :key="route.name"
          :to="route.path"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />

      <template v-slot:append>
        <v-list dense nav>
          <v-list-item link @click="$logout">
            <v-list-item-icon>
              <v-icon>mdi mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kirjaudu ulos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item class="refresh-list-item" link @click="reload">
            <v-list-item-icon>
              <v-icon>mdi mdi-refresh</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>
                <div class="d-flex align-center justify-lg-space-between">
                  <span :class="[version !== latestVersion && 'info--text']">{{
                    versionShort || "##############"
                  }}</span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar :height="$iOS ? 90 : 60" app color="primary" elevation="4">
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          v-if="!prev"
          color="#ffffff"
          @click.stop="$store.dispatch('setDrawer', !drawer)"
        ></v-app-bar-nav-icon>
        <v-btn
          v-if="prev"
          color="white"
          icon
          @click="
            () => {
              if (this.forceFallback) {
                this.$router.push(this.fallback);
                return;
              }
              hasHistory()
                ? this.$router.go(-1)
                : this.$router.push(this.fallback);
            }
          "
        >
          <v-icon>mdi mdi-arrow-left</v-icon>
        </v-btn>
        <h3 style="color: #ffffff">{{ title }}</h3>
      </div>
    </v-app-bar>

    <v-main class="fill-height">
      <v-container
        :class="[$vuetify.breakpoint.smAndDown && 'pa-0']"
        :fluid="fluid"
        class="fill-height"
      >
        <v-col class="fill-height pt-5" cols="12">
          <v-alert v-if="$isMissingUserRole" outlined type="error"
            >Sinulla ei ole oikeuksia nähdä sivua. Kirjaudu ulos ja ole
            yhteydessä Joni Rautiaiseen.
          </v-alert>
          <slot></slot>
        </v-col>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { routes } from "@/router";

export default {
  name: "BasicPage",
  components: {},
  props: {
    title: String,
    prev: Boolean,
    fluid: {
      type: Boolean,
      default: true,
    },
    fallback: Object,
    forceFallback: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.$isMissingUserRole) {
      this.$toast(
        "Ei oikeuksia. Ota yhteyttä Joni Rautiaiseen. Sinut kirjataan ulos 5 sekunnin päästä."
      );
      setTimeout(() => {
        this.$logout();
      }, 5000);
    }
  },
  data: () => {
    return {};
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    reload() {
      if (window.swenv) {
        localStorage.setItem("swenv", window.swenv);
      }

      window.location.reload();
    },
  },
  computed: {
    version() {
      return window.installedVersion;
    },
    latestVersion() {
      return window.latestVersion;
    },
    versionShort() {
      return (this.version || "").split("-")[0];
    },
    drawer() {
      return this.$store.state.drawer;
    },
    routes() {
      const r = routes.filter((v) => v.nav !== false && !v.part);
      return r.filter((v) => !v.roles || this.$hasRoleIn(v.roles));
    },
    systemRoutes() {
      const r = routes.filter((v) => v.nav !== false && v.part === "system");
      return r.filter((v) => !v.roles || this.$hasRoleIn(v.roles));
    },
  },
};
</script>

<style lang="scss">
.nav-bar {
  background-color: #6c0000;
}

.mb-ios {
  margin-bottom: 90px;
}

.mb-normal {
  margin-bottom: 60px;
}

.refresh-list-item {
}
</style>
