<template>
  <basic-page fluid title="Raportointi">
    <v-row class="fill-height">
      <v-col cols="12" lg="10" offset-lg="1" offset-xl="3" xl="6">
        <v-card class="mt-5">
          <v-card-title
            >Raportointi
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <modal-picker v-model="start" label="Aloituspäivämäärä" />
              </v-col>
              <v-col cols="12" lg="6">
                <modal-picker v-model="end" label="Lopetuspäivämäärä" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <v-btn
              :loading="loading"
              color="primary"
              @click="downloadHourReport"
            >
              Lataa hälytysten tuntiraportti
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import ModalPicker from "@/components/ModalPicker";
import api from "@/api";
import downloadjs from "downloadjs";

export default {
  name: "Henkilot",
  components: { ModalPicker, BasicPage },
  data: () => {
    return {
      loading: false,
      start: null,
      end: null,
    };
  },
  computed: {
    today() {
      return dayjs().format("LLLL");
    },
    henkilot() {
      return this.$store.state.henkilot;
    },
  },
  methods: {
    async b64toBlob(base64, type = "application/octet-stream") {
      return await fetch(`data:${type};base64,${base64}`).then((res) =>
        res.blob()
      );
    },
    async downloadHourReport() {
      this.loading = true;
      if (!this.start || !this.end) {
        this.$toast("Aloitus- ja lopetuspäivämäärä pitää olla syötetty");
        return;
      }
      try {
        const halytykset = (
          await api.hourReport({ start: this.start, end: this.end })
        ).data;
        downloadjs(
          await this.b64toBlob(
            halytykset.data,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ),
          `tuntiraportti-${this.start}-${this.end}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      } catch (e) {
        this.$toast("Raporttidataa ei voitu ladata.", { type: "error" });
      }

      this.loading = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>