import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { signOut } from "firebase/auth";
import { auth } from "@/plugins/firebase.app";
import { orderBy } from "lodash";
import api from "@/api";

Vue.use(Vuex);
const initialState = {
  drawer: null,
  user: null,
  harjoitukset: [],
  evyharjoitukset: [],
  digivihot: [],

  henkilot: [],
  yksikot: [
    /* HUOM JOS MUOKKAAT NIIN MUOKKAA MYÖS JSREPORTSI */
    {
      id: 1,
      name: "RSA511",
      color: "green lighten-5",
    },
    {
      id: 2,
      name: "RSA5112",
      color: "amber  lighten-5",
    },
    {
      id: 3,
      name: "RSA513",
      color: "blue-grey lighten-5",
    },
    {
      id: 4,
      name: "RSA517",
      color: "lime lighten-5",
    },
    {
      id: 5,
      name: "RSA518",
      color: "blue lighten-5",
    },
    {
      id: 6,
      name: "RSA5182",
      color: "cyan lighten-5",
    },
    {
      id: 7,
      name: "RSA5188",
      color: "brown lighten-5",
    },
    {
      id: 100,
      name: "Asema",
      color: "grey lighten-5",
    },
  ],
};
export default new Vuex.Store({
  state: { ...initialState },
  mutations: {
    setDrawer(state, show) {
      state.drawer = show;
    },
    setUser(state, currentUser) {
      if (!currentUser) {
        state.user = null;
        axios.defaults.headers.common = {
          Authorization: null,
        };
        window.localStorage.removeItem("user");
        Object.assign(state, { ...initialState });
        return;
      }
      axios.defaults.headers.common = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUser.token?.access_token}`,
      };
      state.user = currentUser;
    },
    async logout(state) {
      Vue.$toast("Kirjauduttu ulos", {
        type: "info",
      });
      state.user = null;
      axios.defaults.headers.common = {
        Authorization: null,
      };

      await signOut(auth);
    },
    setDigivihot(state, digivihot) {
      state.digivihot = digivihot;
    },
    setHarjoitukset(state, harjoitukset) {
      state.harjoitukset = harjoitukset;
    },
    setEvyHarjoitukset(state, evyharjoitukset) {
      state.evyharjoitukset = evyharjoitukset;
    },
    setHenkilot(state, henkilot) {
      state.henkilot = henkilot;
    },
  },
  actions: {
    setDrawer({ commit }, payload) {
      commit("setDrawer", payload);
    },
    updateUser({ commit }, payload) {
      commit("setUser", payload.currentUser);
    },
    logout({ commit }) {
      commit("logout");
    },
    async loadHarjoitukset({ commit, state }) {
      if (state.harjoitukset.length > 0) return;
      let response = (await api.harjoituksetList()).data;
      const harjoitukset = orderBy(response.harjoitukset, ["date"], ["asc"]);
      commit("setHarjoitukset", harjoitukset);

      const evyharjoitukset = orderBy(response.evy, ["date"], ["asc"]);
      commit("setEvyHarjoitukset", evyharjoitukset);
    },
    async loadDigivihot({ commit, state }, payload) {
      let response = (await api.digivihotListPaged({ year: payload })).data;
      const digivihot = orderBy(response, ["date", "time"], ["desc", "desc"]);
      commit("setDigivihot", digivihot);
    },
    async loadHenkilot({ commit, state }) {
      let response = (await api.henkilotList()).data;
      const henkilot = orderBy(response, ["name"], ["asc"]);
      commit("setHenkilot", henkilot);
    },
  },
  modules: {},
});
