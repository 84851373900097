import Vue from "vue";
import VueRouter from "vue-router";
import Vaaratilannehavainto from "@/views/Vaaratilannehavainto";
import Ilmoitustaulu from "@/views/Ilmoitustaulu";
import Login from "@/views/Login";
import Harjoitukset from "@/views/Harjoitukset";
import Ilmoitus from "@/views/Ilmoitus";
import store from "@/store/index";
import Digivihko from "@/views/Digivihko";
import Digivihot from "@/views/Digivihot";
import Henkilot from "@/views/Henkilot";
import Kayttajat from "@/views/Kayttajat";
import Raportointi from "@/views/Raportointi";
import Leirikoski from "@/views/Leirikoski";
import Kalustonhuolto from "@/views/Kalustonhuolto.vue";
import Tiedostopankki from "@/views/Tiedostopankki.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/ilmoitustaulu",
    nav: false,
  },
  {
    path: "/ilmoitustaulu",
    name: "Ilmoitustaulu",
    label: "Ilmoitustaulu",
    component: Ilmoitustaulu,
  },
  {
    path: "/login",
    name: "Login",
    label: "Sisäänkirjautuminen",
    component: Login,
    nav: false,
  },
  {
    path: "/harjoitukset",
    name: "Harjoitukset",
    label: "Harjoitukset",
    component: Harjoitukset,
    roles: ["user", "admin"],
  },
  {
    path: "/turvallisuusilmoitus",
    name: "Turvallisuusilmoitus",
    label: "Turvallisuusilmoitus",
    component: Vaaratilannehavainto,
  },
  {
    path: "/tiedostopankki",
    name: "Tiedostopankki",
    label: "Tiedostopankki",
    component: Tiedostopankki,
    roles: ["user"],
  },
  {
    path: "/ilmoitustaulu/ilmoitus/",
    name: "Ilmoitus",
    label: "Ilmoitus",
    component: Ilmoitus,
    nav: false,
  },
  {
    path: "/ilmoitustaulu/ilmoitus/:id",
    name: "IlmoitusById",
    component: Ilmoitus,
    nav: false,
  },
  {
    path: "/kalustonhuolto",
    name: "Kalustonhuolto",
    label: "Kalustonhuolto",
    component: Kalustonhuolto,
    roles: ["user"],
  },
  {
    path: "/leirikoski",
    name: "Leirikoski",
    label: "Leirikoski",
    component: Leirikoski,
    roles: ["user"],
  },
  {
    path: "/digivihot",
    name: "Digivihot",
    label: "Digivihot",
    component: Digivihot,
    roles: ["admin"],
  },

  {
    path: "/digivihot/d/:id",
    name: "DigivihkoById",
    component: Digivihko,
    nav: false,
  },
  {
    path: "/digivihot/d/",
    name: "Digivihko",
    label: "Digivihko",
    component: Digivihko,
    nav: false,
  },

  {
    path: "/henkilot",
    name: "Henkilöt",
    label: "Henkilöt",
    component: Henkilot,
    part: "system",
    roles: ["admin"],
  },
  {
    path: "/kayttajat",
    name: "Käyttäjät",
    label: "Käyttäjät",
    component: Kayttajat,
    part: "system",
    roles: ["admin"],
  },
  {
    path: "/raportointi",
    name: "Raportointi",
    label: "Raportointi",
    component: Raportointi,
    part: "system",
    roles: ["admin"],
  },
];
let prev = null;
const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store?.state.user) {
    next({ name: "Login" });
  } else {
    next((vm) => {
      prev = from;
    });
  }
});
export { routes };
export default router;
