import { httpsCallable } from "firebase/functions";
import { functions } from "@/plugins/firebase.app";

const fns = [
  "harjoituksetList",
  "evyharjoituksetList",
  "ilmoituksetList",
  "ilmoitusGet",
  "ilmoitusDelete",
  "ilmoitusAddFile",
  "ilmoitusDeleteFile",
  "ilmoitusIlmoittautuminen",
  "ilmoitusIlmoittautuminenDelete",
  "ilmoitusIlmoittautuminenDeleteByAnotherUser",
  "vaaratilannehavaintoSend",
  "digivihotList",
  "digivihotListPaged",
  "digivihkoGet",
  "digivihkoUpsert",
  "digivihkoDelete",
  "henkilotList",
  "henkiloUpsert",
  "henkiloDelete",
  "usersList",
  "userSave",
  "hourReport",
  "allasDataSave",
  "allasDataList",
  "kalustonhuoltoUpsert",
  "kalustonhuoltoByWeek",
  "digivihkoAddFile",
  "digivihkoDeleteFile",
  "firebaseMessagingAddToken",
  "tiedostopankkiAddFile",
  "tiedostopankkiAddCategory",
  "tiedostopankkiGetFilesAndCategories",
  "tiedostopankkiDeleteFile",
  "tiedostopankkiDeleteCategory",
];

const fnsv2 = ["ilmoitusUpsert"];
export default Object.fromEntries([
  ...fns.map((fn) => [fn, httpsCallable(functions, fn)]),
  ...fnsv2.map((fn) => [fn, httpsCallable(functions, fn)]),
]);
/*
export default {
  harjoituksetList: httpsCallable(functions, "harjoituksetList"),
  evyharjoituksetList: httpsCallable(functions, "evyharjoituksetList"),
  ilmoituksetList: httpsCallable(functions, "imoituksetList"),
  ilmoitusUpsert: httpsCallable(functions, "ilmoitusUpsert"),
  ilmoitusIlmoittautuminen: httpsCallable(
    functions,
    "ilmoitusIlmoittautuminen"
  ),
};
*/
