<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :block="$vuetify.breakpoint.smAndDown"
  >
    Edellinen</v-btn
  >
</template>

<script>
export default {
  name: "PrevButton",
};
</script>

<style scoped></style>
