<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        hide-details
        label="Haku"
        single-line
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :disable-pagination="true"
      :expanded.sync="expanded"
      :headers="headers"
      :item-class="getRowClass"
      :items="participants"
      :items-per-page="-1"
      :loading="loading"
      :search="search"
      :show-expand="type === 'Hälytys'"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :value="selected"
      group-by="_isParticipant"
      hide-default-footer
      group-desc
      multi-sort
      show-select
      @item-selected="itemSelected"
    >
      <template
        v-slot:group.header="{
          group,
          groupBy,
          headers,
          /*isMobile,
          items,

          isOpen,
          toggle,
          remove,*/
        }"
      >
        <td :colspan="headers.length" class="text-h6">
          {{ group ? "Valittu" : "Ei valittu" }}
          {{ group && participantsCount }}
        </td>
      </template>
      <template v-slot:header.data-table-select="{}"></template>
      <template
        v-slot:item.data-table-expand="{ expand, index, item, isExpanded }"
      >
        <v-btn
          :class="[item._hasExpandedData && 'info--text']"
          icon
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="!isExpanded">mdi mdi-menu-down</v-icon>
          <v-icon v-else>mdi mdi-menu-up</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        <span class="text-subtitle-1">{{ item.name }}</span>
      </template>
      <template v-slot:item.date="{ item }">
        <span
          v-if="item.date && item.engine !== 100"
          :class="[
            item._isAbnormalDate && 'info--text',
            item._isNonValidDate && 'error--text',
          ]"
          class="cursor-pointer"
          @click="changeDate(item)"
          >{{ $formatDateAndHourMinute(item.date + " " + item.time) }}</span
        >
      </template>
      <template v-slot:item.dateReturn="{ item }">
        <v-icon v-if="item._isParticipant && item._diff < 0" color="error"
          >mdi mdi-alert
        </v-icon>
        <span
          v-if="item.dateReturn && item.engine !== 100"
          :class="[
            item._isAbnormalDateReturn && 'info--text',
            item._diff < 0 && 'error--text',
            item._isNonValidDateReturn && 'error--text',
          ]"
          class="cursor-pointer"
          @click="changeDate(item, true)"
          >{{
            $formatDateAndHourMinute(item.dateReturn + " " + item.timeReturn)
          }}
          ({{ duration(item) }})</span
        >
      </template>
      <template v-slot:item.engine="{ item }">
        <v-select
          v-if="item._isParticipant"
          v-model="item.engine"
          :items="yksikot"
          item-text="name"
          item-value="id"
          style="min-width: 120px"
        ></v-select>
      </template>
      <template v-slot:item.yj="{ item }">
        <v-simple-checkbox
          v-if="
            item._isParticipant && type === 'Hälytys' && showYJHalytys(item)
          "
          v-model="item.yj"
          :ripple="false"
          color="primary"
        ></v-simple-checkbox>
        <v-simple-checkbox
          v-if="item._isParticipant && type === 'Muu' && item.isYj"
          v-model="item.yj"
          :ripple="false"
          color="primary"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.driver="{ item }">
        <v-simple-checkbox
          v-if="item._isParticipant && item.engine !== 100"
          v-model="item.kone"
          :ripple="false"
          color="blue lighten-2"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.s1="{ item }">
        <v-simple-checkbox
          v-if="item._isParticipant && showSavutS1(item)"
          v-model="item.s1"
          :ripple="false"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.s2="{ item }">
        <v-simple-checkbox
          v-if="item._isParticipant && showSavutS2(item)"
          v-model="item.s2"
          :ripple="false"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.s3="{ item }">
        <v-simple-checkbox
          v-if="item._isParticipant && showSavutS3(item)"
          v-model="item.s3"
          :ripple="false"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.s4="{ item }">
        <v-simple-checkbox
          v-if="item._isParticipant && showSavutS4(item)"
          v-model="item.s4"
          :ripple="false"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.role="{ item }">
        <v-row
          no-gutters
          align-content="space-between"
          justify="space-between"
          class="w-100 mb-5"
        >
          <v-col
            v-if="
              item._isParticipant && type === 'Hälytys' && showYJHalytys(item)
            "
          >
            <v-row align-content="center" justify="center" no-gutters>
              YJ
            </v-row>
            <v-row align-content="center" justify="center" no-gutters>
              <v-simple-checkbox
                v-model="item.yj"
                :ripple="false"
                color="primary"
              ></v-simple-checkbox>
            </v-row>
          </v-col>
          <v-col v-if="item._isParticipant && item.engine !== 100">
            <v-row align-content="center" justify="center" no-gutters>
              Kone
            </v-row>
            <v-row align-content="center" justify="center" no-gutters>
              <v-simple-checkbox
                v-model="item.kone"
                :ripple="false"
                color="blue lighten-2"
              ></v-simple-checkbox>
            </v-row>
          </v-col>
          <v-col v-if="item._isParticipant && showSavutS1(item)">
            <v-row align-content="center" justify="center" no-gutters>
              S1
            </v-row>
            <v-row align-content="center" justify="center" no-gutters>
              <v-simple-checkbox
                v-model="item.s1"
                :ripple="false"
              ></v-simple-checkbox>
            </v-row>
          </v-col>
          <v-col v-if="item._isParticipant && showSavutS2(item)">
            <v-row align-content="center" justify="center" no-gutters>
              S2
            </v-row>
            <v-row align-content="center" justify="center" no-gutters>
              <v-simple-checkbox
                v-model="item.s2"
                :ripple="false"
              ></v-simple-checkbox>
            </v-row>
          </v-col>
          <v-col v-if="item._isParticipant && showSavutS3(item)">
            <v-row align-content="center" justify="center" no-gutters>
              S3
            </v-row>
            <v-row align-content="center" justify="center" no-gutters>
              <v-simple-checkbox
                v-model="item.s3"
                :ripple="false"
              ></v-simple-checkbox>
            </v-row>
          </v-col>
          <v-col v-if="item._isParticipant && showSavutS4(item)">
            <v-row align-content="center" justify="center" no-gutters>
              S4
            </v-row>
            <v-row align-content="center" justify="center" no-gutters>
              <v-simple-checkbox
                v-model="item.s4"
                :ripple="false"
              ></v-simple-checkbox>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :class="[$vuetify.breakpoint.smAndDown && 'td-table-row']"
          :colspan="2"
          class="pt-2 pb-5"
        ></td>
        <td
          :class="[$vuetify.breakpoint.smAndDown && 'td-table-row']"
          :colspan="1"
          class="pt-2 pb-5"
        >
          <v-checkbox v-model="item.halytysraha" label="Hälytysraha" />
        </td>
        <!--<td
          :colspan="1"
          class="pt-2 pb-5"
          :class="[$vuetify.breakpoint.smAndDown && 'td-table-row']"
        >
          <v-text-field
            hide-details
            v-model="item.smokediving"
            v-if="
              item.smokediving || (item._henkilo && item._henkilo.smokediver)
            "
            label="Savusukellusminuutit"
            persistent-placeholder
            placeholder="esim. 45"
          />
        </td>-->
        <td
          :class="[$vuetify.breakpoint.smAndDown && 'td-table-row']"
          :colspan="2"
          class="pt-2 pb-5"
        >
          <v-text-field
            v-model="item.notices"
            hide-details
            label="Huomautukset"
            persistent-placeholder
            placeholder="esim. Kalustohuolto tehtävän jälkeen"
          ></v-text-field>
        </td>
        <td
          :class="[$vuetify.breakpoint.smAndDown && 'td-table-row']"
          :colspan="headers.length - 5"
          class="pt-2 pb-5"
        ></td>
      </template>
    </v-data-table>
    <v-data-table
      v-if="$vuetify.breakpoint.xsOnly"
      :disable-pagination="true"
      :headers="notSelectedHenkilotHeaders"
      :item-class="getRowClass"
      :items="henkilot"
      :items-per-page="-1"
      :loading="loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :value="selected"
      :mobile-breakpoint="0"
      group-desc
      hide-default-footer
      multi-sort
      show-select
      @item-selected="itemSelected"
    >
      <template
        v-slot:group.header="{
          group,
          groupBy,
          headers,
          /*isMobile,
          items,

          isOpen,
          toggle,
          remove,*/
        }"
      >
        <td :colspan="headers.length" class="text-h6">
          {{ group ? "Valittu" : "Ei valittu" }}
          {{ group && participantsCount }}
        </td>
      </template>
      <template v-slot:header.data-table-select="{}"></template>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-btn
          v-if="!isHenkiloSelected(item)"
          color="primary"
          @click.stop="select()"
          >Valitse
        </v-btn>
        <v-btn v-else color="primary" disabled>Valittu </v-btn>
      </template>
      <template v-slot:item.name="{ item }">
        <span class="text-subtitle-1">{{ item.name }}</span>
      </template>
    </v-data-table>

    <v-dialog v-model="modalParticipantDateEdit" max-width="600px" persistent>
      <v-card v-if="dateEditParticipant">
        <v-card-title
          >Muokkaa henkilön {{ dateEditParticipant.name }}
          {{ modalParticipantDateEditReturn ? "paluuaikaa" : "lähtöaikaa" }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="!modalParticipantDateEditReturn"
                cols="12"
                md="12"
                sm="12"
              >
                <modal-picker
                  v-model="dateEditParticipant.date"
                  label="Lähtöpäivämäärä"
                />
              </v-col>
              <v-col
                v-if="!modalParticipantDateEditReturn"
                cols="12"
                md="12"
                sm="12"
              >
                <modal-picker
                  v-model="dateEditParticipant.time"
                  label="Lähtöaika"
                  type="time"
                />
              </v-col>
              <v-col
                v-if="modalParticipantDateEditReturn"
                cols="12"
                md="12"
                sm="12"
              >
                <modal-picker
                  v-model="dateEditParticipant.dateReturn"
                  label="Paluupäivämäärä"
                />
              </v-col>
              <v-col
                v-if="modalParticipantDateEditReturn"
                cols="12"
                md="12"
                sm="12"
              >
                <modal-picker
                  v-model="dateEditParticipant.timeReturn"
                  label="Paluuaika"
                  type="time"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="dateEditParticipant.notices"
                  hide-details
                  label="Huomautukset"
                  md="12"
                  outlined
                  persistent-placeholder
                  placeholder="esim. Kalustohuolto tehtävän jälkeen"
                  sm="12"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="modalParticipantDateEdit = false">
            Sulje
          </v-btn>
          <v-btn color="primary" text @click="saveModalParticipantDateEdit">
            Hyväksy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ModalPicker from "@/components/ModalPicker";
import dayjs from "dayjs";

export default {
  name: "OsallistujatTable",
  components: { ModalPicker },
  props: {
    digivihko: Object,
    loading: Boolean,
    type: String,
  },
  data() {
    return {
      search: "",
      modalParticipantDateEdit: false,
      modalParticipantDateEditReturn: false,
      dateEditParticipant: null,
      tableHeaders: [
        { text: "Nimi", value: "name" },
        //{ text: "Vakanssi", value: "vacancy" },

        { text: "Lähtöaika", value: "date", cellClass: "width-date" },
        { text: "Paluuaika", value: "dateReturn", cellClass: "w-100" },
        { text: "Yksikkö", value: "engine", type: "Hälytys" },
        { text: "YJ", value: "yj", type: "Hälytys", mobile: false },
        { text: "YJ", value: "yj", type: "Muu" },
        { text: "Kone", value: "driver", type: "Hälytys", mobile: false },
        {
          text: "Rooli",
          value: "role",
          type: "Hälytys",
          mobile: true,
          class: "w-100",
        },
        { text: "S1", value: "s1", type: "Hälytys", mobile: false },
        { text: "S2", value: "s2", type: "Hälytys", mobile: false },
        { text: "S3", value: "s3", type: "Hälytys", mobile: false },
        { text: "S4", value: "s4", type: "Hälytys", mobile: false },
      ],
      notSelectedHenkilotHeaders: [{ text: "Nimi", value: "name" }],

      expanded: [],
      loadingSave: false,
    };
  },
  computed: {
    sortBy() {
      return this.type === "Hälytys" ? ["engine", "name"] : ["name"];
    },
    sortDesc() {
      return this.type === "Hälytys" ? [false, false] : [false];
    },
    headers() {
      const isMobile = this.$vuetify.breakpoint.xsOnly;
      return this.tableHeaders
        .filter((h) => h.type === undefined || h.type === this.type)
        .filter((h) => h.mobile === undefined || h.mobile === isMobile);
    },
    selected() {
      return this.participants.filter((p) => p._isParticipant);
    },
    henkilot() {
      return [...(this.$store.state.henkilot || [])].map((h) => {
        const hh = { ...h };
        hh._isHenkilo = true;
        hh.henkiloId = hh.id;
        return hh;
      });
    },
    yksikot() {
      return this.$store.state.yksikot;
    },
    notSelectedHenkilot() {
      const henkiloIdArray = this.participants
        .filter((p) => p._isParticipant)
        .map((p) => p.henkiloId);
      return this.henkilot.filter((h) => !henkiloIdArray.includes(h.id));
    },
    participants() {
      const participants = this.digivihko.participants
        ? [...this.digivihko.participants]
        : [];
      const henkilot = [...this.henkilot];
      participants.forEach((p) => {
        p._isParticipant = true;
        p._diff = this.diff(p);
        p._isAbnormalDate = this.isAbnormalDate(p);
        p._isAbnormalDateReturn = this.isAbnormalDate(p, true);
        p._isNonValidDate = !dayjs(p.date + " " + p.time).isValid();
        p._isNonValidDateReturn = !dayjs(
          p.dateReturn + " " + p.timeReturn
        ).isValid();
        p._hasExpandedData = !!(p.smokediving || !p.halytysraha || p.notices);

        if (p.henkiloId && henkilot) {
          p._henkilo = henkilot?.find((h) => h.id === p.henkiloId);
        }
      });

      const henkiloIdArray = participants.map((p) => p.henkiloId);
      return [
        ...participants,
        ...(this.$vuetify.breakpoint.smAndUp
          ? henkilot.filter((h) => !henkiloIdArray.includes(h.id))
          : []),
      ];
    },
    participantsCount() {
      return this.participants.filter((p) => p._isParticipant).length;
    },
  },
  methods: {
    isHenkiloSelected(henkilo) {
      return this.participants
        .filter((p) => p._isParticipant)
        .find((p) => p.henkiloId === henkilo.id);
    },
    showYJHalytys(item) {
      return item.engine === 1 || item.engine === 2;
    },
    showSavutS1(item) {
      return (
        item.engine === 1 ||
        item.engine === 2 ||
        item.engine === 3 ||
        item.engine === 4
      );
    },
    showSavutS2(item) {
      return item.engine === 1 || item.engine === 2 || item.engine === 4; // 4 == RSA517
    },
    showSavutS3(item) {
      return item.engine === 1 || item.engine === 2 || item.engine === 4;
    },
    showSavutS4(item) {
      return item.engine === 1 || item.engine === 2 || item.engine === 4;
    },
    diff(item) {
      if (item.date && item.time && item.dateReturn && item.timeReturn) {
        const startTime = dayjs(
          item.date + " " + item.time,
          "YYYY-MM-DD HH:mm"
        );
        const returnTime = dayjs(
          item.dateReturn + " " + item.timeReturn,
          "YYYY-MM-DD HH:mm"
        );
        return returnTime.diff(startTime);
      }
      return null;
    },
    duration(item) {
      const diff = this.diff(item);
      if (diff) {
        const dur = dayjs.duration(diff);
        let f = dur.format("H[h] m[m]");
        if (dur.as("days") > 1) {
          f = dur.format("D[d] H[h] m[m]");
        }
        return f;
      }
      return null;
    },
    durationAsMinutes(item) {
      const diff = this.diff(item);
      if (diff) {
        const dur = dayjs.duration(diff);
        return dur.as("minutes");
      }
      return null;
    },
    saveModalParticipantDateEdit() {
      if (
        this.modalParticipantDateEditReturn === true &&
        dayjs(
          this.dateEditParticipant.date + " " + this.dateEditParticipant.time
        ).isAfter(
          dayjs(
            this.dateEditParticipant.dateReturn +
              " " +
              this.dateEditParticipant.timeReturn
          )
        )
      ) {
        this.$toast("Paluuaika ei voi olla ennen lähtöaikaa", {
          type: "error",
        });
        return;
      }

      const participants = this.digivihko.participants
        ? [...this.digivihko.participants]
        : [];
      const participantIndex = participants.findIndex(
        (p) => p.id === this.dateEditParticipant.id
      );
      if (participantIndex > -1) {
        participants[participantIndex] = {
          ...participants[participantIndex],
          ...(this.modalParticipantDateEditReturn
            ? {
                dateReturn: this.dateEditParticipant.dateReturn,
                timeReturn: this.dateEditParticipant.timeReturn,
                notices: this.dateEditParticipant.notices,
              }
            : {
                date: this.dateEditParticipant.date,
                time: this.dateEditParticipant.time,
                notices: this.dateEditParticipant.notices,
              }),
        };
      }
      this.digivihko.participants = participants;
      this.modalParticipantDateEdit = false;
      this.modalParticipantDateEditReturn = false;
      this.dateEditParticipant = null;
    },
    isAbnormalDate(participant, isReturn = false) {
      if (isReturn === false) {
        return (
          this.digivihko.date + " " + this.digivihko.time !==
          participant.date + " " + participant.time
        );
      } else {
        return (
          this.digivihko.dateReturn + " " + this.digivihko.timeReturn !==
          participant.dateReturn + " " + participant.timeReturn
        );
      }
    },
    getRowClass(item) {
      return this.type === "Hälytys"
        ? this.yksikot?.find((y) => y.id === item.engine)?.color
        : "";
    },
    itemSelected({ item, value }) {
      if (value === true) {
        this.digivihko.participants = [
          ...(this.digivihko.participants || []),
          {
            ...item,
            isYj: item.isYj, // Henkilö on YJ -> oikeutettu YJn palkkaan jos yj: true
            id: this.$uuidv4(),
            defaultEngine: undefined,
            date: this.digivihko.date,
            time: this.digivihko.time,
            dateReturn: this.digivihko.dateReturn,
            timeReturn: this.digivihko.timeReturn,
            engine: item.defaultEngine,
            yj: null,
            halytysraha: true,
            notices: null,
          },
        ];
      } else {
        this.digivihko.participants = (
          this.digivihko.participants || []
        ).filter((p) => p.henkiloId !== item.henkiloId);
      }
    },
    changeDate(item, isReturn = false) {
      this.dateEditParticipant = {
        ...item,
      };

      this.modalParticipantDateEditReturn = isReturn;
      this.modalParticipantDateEdit = true;
    },
  },
  async mounted() {
    this.loadingOsallistujat = true;
    await this.$store.dispatch("loadHenkilot");
    this.loadingOsallistujat = false;
  },
};
</script>

<style lang="scss">
.td-table-row {
  display: flex;
  height: 100% !important;
}

.w-100 {
  width: 100%;
}

.v-data-table__mobile-row__cell {
  flex: 1;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0 !important;
}
.width-date {
  width: 150px;
  min-width: 150px;
}
</style>
