/* eslint-disable no-console */

import { register } from "register-service-worker";

//register(`${process.env.BASE_URL}firebase-messaging-sw.js`);
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
    ready(registeration) {
      console.log("App is being served from cache by a service worker.");
      if (registeration.active) {
        registeration.active.postMessage({
          type: "FCM",
        });
      }
    },
    registered() {
      console.log("Service worker has been registered.");
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("message", (event) => {
          // event is a MessageEvent object

          console.log("event:", event);
          if (event.data?.type === "VERSION") {
            window.installedVersion = event.data.installedVersion;
            window.latestVersion = event.data.latestVersion;
          }
        });

        try {
          if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
              type: "VERSION_REQUEST",
            });
          }
          setTimeout(() => {
            if (navigator.serviceWorker.controller) {
              navigator.serviceWorker.controller.postMessage({
                type: "VERSION_REQUEST",
              });
            }
          }, 10000);
        } catch (e) {
          console.error(e);
        }
      }
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; Refresh...");
      setTimeout(() => {
        window.location.reload(true);
      }, 2000);
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
