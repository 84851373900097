<template>
  <basic-page title="Henkilöt" fluid>
    <v-row class="fill-height">
      <v-col cols="12" lg="10" xl="6" offset-xl="3" offset-lg="1">
        <v-card class="mt-5">
          <v-card-title
            >Henkilöt
            <v-spacer />
            <div>
              <v-btn color="primary" rounded @click="createHenkilo">
                <v-icon>mdi mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <henkilot-table
              :henkilot="henkilot"
              :loading="loading"
              ref="table"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import dayjs from "dayjs";
import HenkilotTable from "@/components/HenkilotTable";

export default {
  name: "Henkilot",
  components: { HenkilotTable, BasicPage },
  data: () => {
    return {
      loading: false,
    };
  },
  computed: {
    today() {
      return dayjs().format("LLLL");
    },
    henkilot() {
      return this.$store.state.henkilot;
    },
  },
  methods: {
    async reloadHenkilot() {
      this.loading = true;
      await this.$store.dispatch("loadHenkilot");
      this.loading = false;
    },
    reloadAll() {
      this.reloadHenkilot();
    },
    createHenkilo() {
      this.$refs.table.edit({});
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style scoped lang="scss"></style>