import { render, staticRenderFns } from "./Henkilot.vue?vue&type=template&id=44b0821c&scoped=true&"
import script from "./Henkilot.vue?vue&type=script&lang=js&"
export * from "./Henkilot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b0821c",
  null
  
)

export default component.exports