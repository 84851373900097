<template>
  <v-list-item
    :key="$id(ilmoitus)"
    :class="[$vuetify.breakpoint.smAndDown && 'd-flex flex-column']"
  >
    <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
      <v-list-item-action-text>
        <template v-for="department in departments">
          <div :key="department">
            <v-chip x-small>{{ department }}</v-chip>
          </div>
        </template>
        <div>
          {{ $formatDateAndHourMinute(ilmoitus.createdAt || ilmoitus.created) }}
        </div>
        <div>
          {{ (ilmoitus.createdBy && ilmoitus.createdBy.user) || ilmoitus.user }}
        </div>
      </v-list-item-action-text>
    </v-list-item-action>
    <v-list-item-content
      :class="[$vuetify.breakpoint.smAndDown && 'align-self-start']"
    >
      <v-list-item-title class="text-h6 text-wrap">
        <v-icon v-if="ilmoitus.type === 'ilmoitus'" class="mr-2" size="16"
          >mdi mdi-exclamation-thick
        </v-icon>
        <v-icon
          v-if="ilmoitus.type === 'ilmoittautuminen'"
          class="mr-2"
          size="16"
          >mdi mdi-account-multiple
        </v-icon>
        <span class="text-decoration-underline">{{ ilmoitus.title }}</span>
        <v-btn
          v-if="hasEditRights(ilmoitus)"
          class="text-decoration-none"
          icon
          @click.stop="moveToIlmoitus(ilmoitus)"
        >
          <v-icon size="16">mdi mdi-pencil</v-icon>
        </v-btn>
        <div v-if="ilmoitus.dateStart">
          <span class="text-subtitle-2">
            {{
              $capitalFirstLetter(
                $formatWeekDay(
                  ilmoitus.dateStart + " " + (ilmoitus.timeStart || "00:00:00")
                )
              )
            }}
            {{
              $formatDateAndHourMinute(
                ilmoitus.dateStart + " " + (ilmoitus.timeStart || "00:00:00")
              )
            }}</span
          >

          <span v-if="ilmoitus.dateEnd" class="text-subtitle-2">
            -
            {{
              $capitalFirstLetter(
                $formatWeekDay(
                  ilmoitus.dateEnd + " " + (ilmoitus.timeEnd || "00:00:00")
                )
              )
            }}
            {{
              $formatDateAndHourMinute(
                ilmoitus.dateEnd + " " + (ilmoitus.timeEnd || "00:00:00")
              )
            }}</span
          >
        </div>
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap mt-3"
        style="white-space: pre-line !important"
      >
        {{ ilmoitus.content }}
      </v-list-item-subtitle>

      <ilmoittautuminen :ilmoitus.sync="ilmoitus" @reload="reloadIlmoitukset" />

      <template v-for="(file, i) in ilmoitus.files">
        <v-list-item-subtitle
          :key="i"
          class="text-wrap info--text d-flex align-center mb-3 mt-6 cursor-pointer"
          @click="openFile(file)"
        >
          <v-icon>mdi mdi-paperclip</v-icon>
          <div class="text-wrap" style="word-break: break-word">
            {{ file.filename }}
          </div>
        </v-list-item-subtitle>
      </template>
    </v-list-item-content>

    <v-list-item-content
      v-if="$vuetify.breakpoint.smAndDown"
      class="text-right mb-n12 text-body-2 align-self-end"
    >
      <small>
        <template v-for="department in departments">
          <div :key="department" class="mb-2">
            <v-chip x-small>{{ department }}</v-chip>
          </div>
        </template>
        {{ $formatDateAndHourMinute(ilmoitus.createdAt || ilmoitus.created) }}
        {{ (ilmoitus.createdBy && ilmoitus.createdBy.user) || ilmoitus.user }}
      </small>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import { getBlob, ref } from "firebase/storage";
import { ilmoitusStorage } from "@/plugins/firebase.app";
import downloadjs from "downloadjs";
import Ilmoittautuminen from "@/components/Ilmoitustaulu/Ilmoittautuminen.vue";

export default {
  name: "ilmoitus-list-item",
  components: { Ilmoittautuminen },
  props: {
    ilmoitus: {},
  },
  emits: ["reload"],
  computed: {
    departments() {
      return (
        this.ilmoitus.departments?.map((d) => {
          const deps = {
            naisosasto: "Tukiosasto",
            halytysosasto: "Hälytysosasto",
          };
          return deps[d] || d;
        }) || []
      );
    },
  },
  methods: {
    async openFile(file) {
      try {
        const theBlob = await getBlob(ref(ilmoitusStorage, file.key));
        downloadjs(theBlob, file.filename, file.fileType);
      } catch (error) {
        console.error(error);
        this.$toast("Tiedostoa ei voitu avata. Yritä uudelleen.", {
          type: "error",
        });
      }
    },
    hasEditRights(ilmoitus) {
      if (!this.$isAdmin && this.$isNaisosasto) {
        return !ilmoitus?.departments?.includes("halytysosasto");
      }
      return this.$isAdmin || this.$isNaisosasto;
    },
    moveToIlmoitus(ilmoitus) {
      this.$router.push({
        name: "IlmoitusById",
        params: { id: this.$id(ilmoitus) },
      });
    },
    reloadIlmoitukset() {
      this.$emit("reload");
    },
  },
};
</script>
