<template>
  <div>
    <v-simple-table v-if="$vuetify.breakpoint.mdAndUp">
      <tbody>
        <tr
          v-for="harjoitus in harjoitukset"
          :key="$id(harjoitus)"
          :class="[
            $id(nextHarjoitus) === $id(harjoitus) &&
              'blue lighten-5 font-weight-bold',
          ]"
        >
          <td :class="[notRegular(harjoitus) && 'red--text']">
            {{ $formatWeekDayDateAndHourMinute(harjoitus.date) }}
          </td>
          <td>{{ harjoitus.subject }}</td>
          <td>{{ harjoitus.type }}</td>
          <td>{{ harjoitus.location }}</td>
          <td>{{ harjoitus.info }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-list v-else>
      <template v-for="(harjoitus, i) in harjoitukset">
        <v-list-item
          :key="$id(harjoitus)"
          :class="[
            $id(nextHarjoitus) === $id(harjoitus) &&
              'blue lighten-5 font-weight-bold',
          ]"
        >
          <v-list-item-content>
            <v-list-item-title>
              <div :class="[notRegular(harjoitus) && 'red--text']">
                {{ $formatWeekDayDateAndHourMinute(harjoitus.date) }}
              </div>
            </v-list-item-title>

            <v-list-item-subtitle class="subtitle-1 text-wrap"
              >{{ harjoitus.subject }}
            </v-list-item-subtitle>
            <div class="subtitle-2 text-wrap">
              {{ harjoitus.type }} {{ harjoitus.location }}
              {{ harjoitus.info }}
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="i"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "HarjoituksetTable",
  props: {
    harjoitukset: Array,
    nextHarjoitus: Object,
    type: {
      type: String,
      default: "hälytysosasto",
    },
  },
  methods: {
    notRegular(harjoitus) {
      const format = dayjs(harjoitus.date).format("dd HH:mm");
      return this.type === "hälytysosasto"
        ? format !== "ma 18:00"
        : this.type === "evy"
        ? format !== "ti 18:00"
        : "";
    },
  },
};
</script>

<style></style>